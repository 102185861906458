import React, { useEffect, useState } from "react";
import { Col, Form, Image, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from "./style.module.css";
import SkillsModel from "models/skills.model";
import { useLocation } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import logo from "../../assets/logo.svg";
import { Card, Container, ListGroup } from "react-bootstrap";
import avatar3 from "../../assets/Avatar3.svg";
import { MdOutlinePersonOutline } from "react-icons/md";
import { FiHome } from "react-icons/fi";
import { FiZap } from "react-icons/fi";
import { MdLogout } from "react-icons/md";
import { FiMinusCircle } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { SiPluscodes } from "react-icons/si";
import { helper } from "lib/helper";
import skillsModel from "models/skills.model";
import Swal from "sweetalert2";

const AddNewSkills = (props) => {
  const [isProfileComplete, setIsProfileComplete] = React.useState(false);
  const [addsLoadig, setAddsLoadig] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [perPage, setPerPage] = useState(20);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const parameters = {};
  for (let [key, value] of searchParams) {
    parameters[key] = value;
  }

  const getListData = async () => {
    setIsLoading(true);
    const data = {
      email: sessionStorage.getItem("userEmail"),
      pageNo: 1,
      limit: 1000000000,
    };
    await SkillsModel.list(data)

      .then((result) => {
        setIsLoading(false);
        if (result) {
          console.log(result, "result");
          setDataList(result.data);
          setCurrentPage(result?.meta?.current_page);
          setPerPage(result?.meta?.per_page);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        // helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  useEffect(() => {
    getListData();
  }, []);

  const [subSkills, setSubSkills] = useState("");
  const getListDataSubSkills = async (id) => {
    const data = {
      categoryId: parseInt(id),
    };
    await SkillsModel.skillNameBycategoryId(data)
      .then((result) => {
        setIsLoading(false);
        if (result) {
          setSubSkills(result?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        // helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  const [selectedValues, setSelectedValues] = useState([]);
  const subSkillsid = selectedValues && selectedValues?.map((item) => item.id);

  const handleSubmit = (event) => {
    setAddsLoadig(true);
    event.preventDefault();
    const data = {
      email: sessionStorage.getItem("userEmail"),
      skills: subSkillsid,
      skillType: "new",
    };
    SkillsModel.addSkillByStudent(data)
      .then((response) => {
        getListDataNewSkills();
        handleClose();
        setSelectedValues("");
        helper.sweetalert.toast(response.msg, "success");
      })
      .catch((error) => {});
  };

  const [loading, setLoading] = useState(false);
  const [newSkills, setNewSkills] = useState([]);
  const getListDataNewSkills = async () => {
    setLoading(true);
    const data = {
      email: sessionStorage.getItem("userEmail"),
      skillType: "new",
    };
    await SkillsModel.oldSkill(data)
      .then((result) => {
        if (result) {
          setLoading(false);
          setNewSkills(result?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        // helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  useEffect(() => {
    getListDataNewSkills();
  }, []);

  const handleDel = async (delId) => {
    const data = {
      email: sessionStorage.getItem("userEmail"),
      studentSkillId: delId,
    };
    Swal.fire({
      title: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonColor: "#6c757d",
      confirmButtonColor: "#dc3545",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
      allowEscapeKey: false,
      reverseButtons: true,
      focusCancel: true,
      customClass: {
        header: "text-danger", // Adding custom class to header for cancel icon color
      },
      buttons: {
        cancel: {
          text: "Cancel",
          value: null, // Set value to null for default selection
        },
        confirm: {
          text: "Delete",
          value: true,
        },
      },
    }).then((res) => {
      skillsModel.deleteSkill(data).then((res) => {
        helper.sweetalert.toast(res.msg);
        getListDataNewSkills();
      });
    });
  };

  return (
    <>
      <div className={`text-justify ${styles.yourSkills} mt-5`}>
        <Container>
          <Row>
            <Col xs={12} lg={12}>
              <>
                <div className="d-flex justify-content-between ">
                  <Container>
                    <div className={styles.profileHeadings}>
                      Skills Want to Build
                    </div>
                  </Container>
                  <div onClick={handleShow} className={styles.profileHeadings}>
                    <FaPlus size={25} style={{ cursor: "pointer" }} />
                  </div>
                </div>
              </>
            </Col>
          </Row>
        </Container>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color:"#01A8CC"
            }}
          >
            <Spinner></Spinner>
          </div>
        ) : (
          <ListGroup className="mb-2" variant="flush">
            {newSkills?.length > 0 ? (
              newSkills &&
              newSkills?.map((item, index) => {
                return (
                  <ListGroup.Item
                    className={`d-flex justify-content-between align-items-start mt-1 ${styles.yourSkills}`}
                  >
                    <div className="ms-2 me-auto mb-3">
                      <div
                        className={`fw-bold ${styles.listHeadings} fontWeight500`}
                      >
                        {item.skill_name}
                      </div>
                    </div>
                    <MdDelete
                      size={20}
                      onClick={() => handleDel(item.id)}
                      style={{ cursor: "pointer", color: "red" }}
                    />
                  </ListGroup.Item>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <span>No skill found</span>
              </div>
            )}
          </ListGroup>
        )}
      </div>

      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onHide={handleClose}>
          <Modal.Title
            style={{ padding: "15px" }}
            id="contained-modal-title-vcenter"
          >
            Add new skill you want to learn
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className={styles.formHeading}>
                  Select Skill
                </Form.Label>
                <div className={`${styles.custommultiselect}`}>
                <Multiselect
               
                  className={`linearBorder `}
                  options={dataList}
                  selectedValues={selectedValues}
                  onSelect={(selectedList, selectedItem) =>
                    setSelectedValues(selectedList)
                  }
                  onRemove={(selectedList, removedItem) =>
                    setSelectedValues(selectedList)
                  }
                  displayValue={"skill_name"}
                />
                 <i className={`fa-solid fa-caret-down ${styles.dropdownicon}`} style={{fontSize:'20px'}}></i>
                  </div>
              </Form.Group>
           
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  float: "right",
                }}
              >
                <div>
                  <Button
                    className={`SubmitBtn ${styles.Btns}`}
                    variant="secondary"
                    size="lg"
                    type="submit"
                  >
                    Add Skills
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewSkills;
