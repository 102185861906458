import axiosInstance from "../lib/axiosInstance";

const UserModel = class {
  constructor() {
    this.baseUrl = "/";
  }

  async list(data = []) {
    return (
      await axiosInstance.get(`${this.baseUrl}`)
    ).data;
  }

  async listbyUserid(data = []) {
    return (
      await axiosInstance.get(`${this.baseUrl}`,{params:data})
    );
  }
  // async removeUser(id = {}) {
  //   return await axiosInstance.delete(`${this.baseUrl}?id=${id}`);
  // }
  async removeUser(data = {}) {
    return await axiosInstance.delete(`${this.baseUrl}?id=${data}`);
  }
  


  async login(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/login/user`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
}

async create(data) {
  try {
      const headers = {
          'Content-Type': 'application/json'
      };
      const jsonData = JSON.stringify(data);
      const response = await axiosInstance.post(`${this.baseUrl}register/user`, jsonData, {headers});
      return response.data;
  } catch (error) {
      console.error("Error creating class:", error);
      throw error; // Rethrow the error for handling at the calling site
  }
}


  async editUser(data = []) {
    return await axiosInstance.put(this.baseUrl, data);
  }
  
  async InterviewSchedule(data = []) {
    console.log(data,"InterviewSchedule")
    return await axiosInstance.post('/shinterview/', data);
  }
 
};

export default new UserModel();
