import axiosInstance from "../lib/axiosInstance";

const SkillModel = class {
  constructor() {
    this.baseUrl = "/show";
  }

  // async list(data = []) {
  //   return (
  //     await axiosInstance.post(`${this.baseUrl}/skillCategory`)
  //   ).data;
  // }

  async list(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/show/allSkillNames`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }

  async listSkill(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/show/skillCategory`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }


 
  async AllPost(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/show/postTimeLine`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }

  async AllUser(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/show/admin/AllUser`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }

  async skillNameBycategoryId(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`${this.baseUrl}/skillNameBycategoryId`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
}


async newSkill(data) {
  try {
      const headers = {
          'Content-Type': 'application/json'
      };
      const jsonData = JSON.stringify(data);
      const response = await axiosInstance.post(`/show/studentSkillBySkillType`, jsonData, {headers});
      return response.data;
  } catch (error) {
      console.error("Error creating class:", error);
      throw error; // Rethrow the error for handling at the calling site
  }
}
async oldSkill(data) {
  try {
      const headers = {
          'Content-Type': 'application/json'
      };
      const jsonData = JSON.stringify(data);
      const response = await axiosInstance.post(`/show/studentSkillBySkillType`, jsonData, {headers});
      return response.data;
  } catch (error) {
      console.error("Error creating class:", error);
      throw error; // Rethrow the error for handling at the calling site
  }
}

async addSkillByStudent(data) {
  try {
      const headers = {
          'Content-Type': 'application/json'
      };
      const jsonData = JSON.stringify(data);
      const response = await axiosInstance.post(`/add/studentSkill`, jsonData, {headers});
      return response.data;
  } catch (error) {
      console.error("Error creating class:", error);
      throw error; // Rethrow the error for handling at the calling site
  }
}

async deleteSkill(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/delete/userSkill`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }

  async UserData(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/show/userByEmail`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }
  

  async AllUserData(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/show/userSugestion`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }

  async AllConnectionDetails(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/user/connectionDetails`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }
  
  




async adduserPost(data) {
  console.log(data,"postkkkk")
  try {
      const headers = {
          'Content-Type': 'application/json'
      };
      const jsonData = JSON.stringify(data);
      const response = await axiosInstance.post(`/add/studentPost`, jsonData, {headers});
      return response.data;
  } catch (error) {
      console.error("Error creating class:", error);
      throw error; // Rethrow the error for handling at the calling site
  }
}
async addPostLike(data) {
    console.log(data,"postkkkk")
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/like/studentPost`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }


  async addPostUnLike(data) {
    console.log(data,"postkkkk")
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/unlike/studentPost`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }

  async addConnection(data) {
    console.log(data,"postkkkk")
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/add/userConnection`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }

async  updateUser(data) {
    console.log(data,"postkkkk")
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/update/user`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }


  async  chat(data) {
    console.log(data,"postkkkk")
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`https://api.skilllinkers.com/`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }
  

  async updateUserNew(data = []) {
    return await axiosInstance.post(`/update/user`, data);
  }

  async allListInhomePage(data = []) {
    return await axiosInstance.get(`/showAll/skillCategory`, data);
  }


  async adduserPostNew(data = []) {
    return await axiosInstance.post(`/add/studentPost`, data);
  }


};

export default new SkillModel();
