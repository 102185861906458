import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import media from "../../assets/media.svg";
import skillsModel from "models/skills.model";
import { helper } from "lib/helper";
import styles from "./style.module.css";
import { FaPlus } from "react-icons/fa6";
import { Card, Col, Row } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import { AiFillEdit } from "react-icons/ai";

function AddAbout(props) {
  const [addsLoadig, setAddsLoadig] = useState(false);

  const [postTitle, setPostTitle] = useState("");
  const [postDescription, setPostDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setPostDescription(props.userData?.user_bio);
  }, [props.userData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPostDescription(value);
  };

  const remainingCharacters = 1000 - postDescription?.length;

  // Function to handle image selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  const handleSubmit = (event) => {
    setAddsLoadig(true);
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("email", props.userData?.email);
    form.append("school_name", props.userData?.school_name);
    form.append("grade_level", props.userData?.grade_level);
    // form.append("profile",  props.userData?.image_path);
    form.append("skills", props.userData?.skill);

    // You can adjust the header configuration according to your API requirements
    skillsModel
      .updateUserNew(form)
      .then((response) => {
        props.getListDataUser();
        setSelectedImage("");
        handleClose();
        helper.sweetalert.toast(response.data.msg, "success");
        setAddsLoadig(false);
      })
      .catch((error) => {
        props.getListDataUser();
        setAddsLoadig(false);
        handleClose();
        // console.log(error);
      });
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="mt-3">
        <Card className={`text-justify ${styles.aboutDesc}`}>
          <Card.Body className="p-0">
            <Row>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Col className={styles.profileHeadings}  mb={10} lg={9}>
                  <p style={{marginLeft:"5px"}}>
                  About
                  </p>
             
                </Col>

                <Col mb={2}>
                  {props.userData && props.userData?.user_bio === "" ? (
                    <Button
                      onClick={handleShow}
                    
                      className={styles.newPostBtn}
                      style={{marginLeft:"20px"}}

                    >
                      <FaPlus color="#01A8CC" />
                      <> </>Add About
                    </Button>
                  ) : (
                    <Button
                      onClick={handleShow}
                      // size="sm"
                      className={styles.newPostBtn}
                      style={{marginLeft:"20px"}}
                    >
                     <AiFillEdit color="#01A8CC" />
                      <> </>Edit About
                    </Button>
                  )}
                </Col>
              </div>
            </Row>
            <p style={{ textAlign: "justify",padding:"10px" }}>
              {props.userData && props.userData?.user_bio}
            </p>
          </Card.Body>
        </Card>
      </div>

      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onHide={handleClose}>
          <Modal.Title id="contained-modal-title-vcenter">
            Add About
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className={styles.postInputLabel}>Post Title</Form.Label>
                        <Form.Control type="text" placeholder="Enter Post Title" name="post_name" autoFocus value={postTitle} onChange={handleInputChange} />
                    </Form.Group> */}
            <div style={{ position: "relative" }}>
              <Form.Label>About</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Enter About Your Self"
                name="about"
                value={postDescription}
                onChange={handleInputChange}
                style={{ position: "relative", width: "100%", height: "200px" }}
                disabled={postDescription >= 1000}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: "5px",
                  right: "5px",
                  fontSize: "0.8em",
                  color: remainingCharacters >= 0 ? "black" : "red",
                }}
              >
                {remainingCharacters >= 0
                  ? `${remainingCharacters} characters remaining`
                  : "Exceeded limit"}
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  float: "right",
                }}
                className="mt-2"
              >
                <div>
                  <Button
                    className={`linearBg ${styles.postBtn}`}
                    variant="secondary"
                    size="lg"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddAbout;
