import React, { useState, useEffect } from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./style.css";
import avatar3 from "../../assets/profile.svg";
import skillsModel from "models/skills.model";
import { helper } from "lib/helper";
import { useLocation, useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Spinner from "react-bootstrap/Spinner";

import  styles from '../Profile/style.module.css'
const SignUpPopUp = (props) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [addsLoadig, setAddsLoadig] = useState(false);
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [perPage, setPerPage] = useState(20);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const parameters = {};
  for (let [key, value] of searchParams) {
    parameters[key] = value;
  }

  const getListData = async () => {
    setIsLoading(true);
    const data = {
      email: sessionStorage.getItem("userEmail"),
      pageNo: 1,
      limit: 1000000000,
    };
    await skillsModel
      .list(data)

      .then((result) => {
        setIsLoading(false);
        if (result) {
          console.log(result, "result");
          setDataList(result.data);
          setCurrentPage(result?.meta?.current_page);
          setPerPage(result?.meta?.per_page);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        // helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  useEffect(() => {
    getListData();
  }, []);

  const [subSkills, setSubSkills] = useState("");
  const getListDataSubSkills = async (id) => {
    const data = {
      categoryId: parseInt(id),
    };
    await skillsModel
      .skillNameBycategoryId(data)
      .then((result) => {
        setIsLoading(false);
        if (result) {
          setSubSkills(result?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  const [selectedValues, setSelectedValues] = useState([]);
  const subSkillsid = selectedValues && selectedValues?.map((item) => item.id);

  const [schoolName, setSchoolName] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "school_name") {
      setSchoolName(value);
    } else if (name === "grade_level") {
      setGradeLevel(value);
    }
  };

  const handleSubmit = (event) => {
    setAddsLoadig(true);
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("email", props.data || sessionStorage.getItem("userEmail"));
    form.append("school_name", schoolName);
    form.append("grade_level", gradeLevel);
    form.append("skills", subSkillsid);
    form.append("about", "");
    skillsModel
      .updateUserNew(form)
      .then((response) => {
        if (props.uid === "login") {
          navigate("/profile");
        }
        helper.sweetalert.toast(response.data.msg, "success");
        props.setActiveTab("login");
        props.onHide();
        console.log(response, "sdhgfsdj");
        setAddsLoadig(false);
      })
      .catch((error) => {
        setAddsLoadig(false);
        // console.log(error);
      });
  };
  return (
    <Modal
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onHide={props.onHide}>
        <Modal.Title
          style={{ padding: "15px" }}
          id="contained-modal-title-vcenter"
        >
          <Container>
            <div> Complete Your Profile</div>
          </Container>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Form onSubmit={handleSubmit}>
            <Col xs={6} md={12}>
              <div className="text-center">
                <label htmlFor="fileInput" className="image-input">
                  <div className="rounded-gradient-borders">
                    {selectedImage ? (
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "100%",
                        }}
                        src={selectedImage}
                        alt="Selected Profile"
                        name="profile"
                      />
                    ) : (
                      <img
                        style={{
                          height: "50px",
                          width: "50px",
                          paddingTop: "20px",
                        }}
                        src={avatar3}
                        alt="Default Profile"
                        name="profile"
                      />
                    )}
                  </div>
                  <p className="mt-1">Add Profile Picture</p>
                </label>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  name="profile"
                />
              </div>
            </Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>School Name or Educational Institute</Form.Label>
              <Form.Control
                className="linearBorder"
                // size="lg"
                type="text"
                placeholder="Enter School Name"
                name="school_name"
                autoFocus
                required
              />
            </Form.Group>

            {/* <Form.Group className="mb-3" controlId="formBasicEmail"> */}

            {/* <Multiselect
                className="linearBorder"
                size="lg"
                options={dataList}
                selectedValues={selectedValues}
                onSelect={(selectedList, selectedItem) =>
                  setSelectedValues(selectedList)
                }
                onRemove={(selectedList, removedItem) =>
                  setSelectedValues(selectedList)
                }
                displayValue={"skill_name"}
              />
            </Form.Group> */}

              <Form.Label>Skills/Intrest You Have</Form.Label>
            <div className={`${styles.custommultiselect}`}>
              <Multiselect
                className="linearBorder"
                size="lg"
                options={dataList}
                selectedValues={selectedValues}
                onSelect={(selectedList, selectedItem) =>
                  setSelectedValues(selectedList)
                }
                onRemove={(selectedList, removedItem) =>
                  setSelectedValues(selectedList)
                }
                displayValue={"skill_name"}
              />
              <i
                className={`fa-solid fa-caret-down ${styles.dropdownicon}`}
                style={{ fontSize: "20px" }}
              ></i>
            </div>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Grade Lavel</Form.Label>
              <Form.Select
                className="linearBorder"
                // size="lg"
                aria-label="Default select example"
                name="grade_level"
                onChange={(event) => setGradeLevel(event.target.value)}
                required
              >
                <option>Select Grade Level</option>
                <option value="1st">1st</option>
                <option value="2nd">2nd</option>
                <option value="3rd">3rd</option>
                <option value="4th">4th</option>
                <option value="5th">5th</option>
                <option value="6th">6th</option>
                <option value="7th">7th</option>
                <option value="8th">8th</option>
                <option value="9th">9th</option>
                <option value="10th">10th</option>
                <option value="11th">11th</option>
                <option value="12th">12th</option>

                <option value="Diploma">Diploma</option>
                <option value="Graduate">Graduate</option>
                <option value="PostGraduate">Post Graduate</option>
                <option value="PHED">PHED</option>
              </Form.Select>
            </Form.Group>
            <div className="d-grid gap-2">
              <Button
                className="linearBg"
                variant="secondary"
                size="lg"
                type="submit"
              >
                {addsLoadig ? (
                  <Spinner animation="border" />
                ) : (
                  <span>Continue</span>
                )}
              </Button>
            </div>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SignUpPopUp;
