import React, { useEffect } from "react";
import "./style.module.css";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  ListGroup,
  Row,
} from "react-bootstrap";
import { FiPlusCircle } from "react-icons/fi";
import person from "../../assets/person.svg";
import homepagenew from '../../assets/homepagenew.jpeg'

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Yahaan par scroll ko top par laane ke liye window.scrollTo ka istemal kiya gaya hai
  }, []);
  return (
    <div>
      <Container>
        <div className="text-center">
          <div className="gradiant-text text-center gradiantHeadings">
          Terms And Conditions
          </div>
          {/* <p className="subText">
            Everything you need to know about the skills.
          </p> */}
        </div>
        <div>
          <Container>
            <div className="mt-5 mb-5">
            
              <div style={{ textAlign: "justify", marginLeft: "15px" }}>
              <p className="mt-4 home-text2">
                 
              Welcome to Skill Linkers, a platform dedicated to connecting high school students based on their unique skills and interests. By accessing or using our website and services, you agree to comply with these Terms of Service.
                </p>
                <p className="mt-4 home-text2">
                  <span className="home-text"> Acceptance of Terms : </span>
                  By using Skill Linkers, you agree to abide by these Terms of Service and any additional guidelines or policies referenced herein. If you do not agree to these terms, please do not use our platform.
                </p>

                <p className="mt-4 home-text2">
  <span className="home-text">User Conduct : </span>
  Users must conduct themselves in a respectful and professional manner. You agree not to engage in any behavior that violates our community guidelines, including but not limited to:
    <ul>
      <li>Posting or sharing any form of discriminatory, offensive, or inappropriate content.</li>
      <li> Harassing, bullying, or threatening other users.</li>
      <li>Engaging in illegal activities or promoting harmful behavior.</li>
    </ul>

  
  <span className="home-text">Content Moderation : </span>
  All chat logs and user-generated content shared on Skill Linkers are saved for moderation purposes.
  The platform's administrators have the right to review and delete any inappropriate or offensive content.
  Users are responsible for their content and must adhere to our community guidelines. Prohibited content includes but is not limited to:
 
    <ul>
      <li>Discriminatory or hate speech.</li>
      <li>Offensive language or imagery.</li>
      <li>Harassment, bullying, or threats.</li>
      <li>Illegal activities or promoting harmful behavior.</li>
      <li>Spam or unauthorized advertising.</li>
      <li>Violations of intellectual property rights.</li>
    </ul>
    <p>

  
    Users who violate these guidelines may have their content removed, and their accounts may be suspended or terminated.
    Skill Linkers aims to maintain a safe and respectful environment for all users, and your cooperation in upholding these standards is appreciated.
    </p>
</p>

                <p className="mt-4 home-text2">
                  <span className="home-text"> Privacy and Data Security : </span>
                  We take user privacy and data security seriously. By using Skill Linkers, you agree to our Privacy Policy, which outlines how we collect, use, and protect your personal information. We do not sell or share your data with third parties without your consent.
                </p>

                <p className="mt-4 home-text2">
                  <span className="home-text"> Intellectual Property : </span>
                  Users retain ownership of their intellectual property rights for content they create and share on Skill Linkers. By posting content, you grant us a non-exclusive, royalty-free license to use, modify, and distribute the content solely for the purpose of operating and improving our platform.
                </p>

                <p className="mt-4 home-text2">
                  <span className="home-text"> Third-Party Links : </span>
                  Skill Linkers may contain links to third-party websites or services. We are not responsible for the content, privacy practices, or terms of use of these third-party sites. Use them at your own risk.
                </p>

                <p className="mt-4 home-text2">
                  <span className="home-text">Termination of Accounts : </span>
                  We reserve the right to suspend or terminate user accounts that violate these Terms of Service or pose a risk to our community. We may also remove or restrict access to content that violates our guidelines.
                </p>

                <p className="mt-4 home-text2">
                  <span className="home-text">Changes to Terms : </span>
                  We may update or modify these Terms of Service at any time without prior notice. It is your responsibility to review these terms periodically for changes. Continued use of Skill Linkers after updates constitutes acceptance of the revised terms.
                </p>



                <p className="mt-4 home-text2">
                  <span className="home-text">Disclaimer of Warranties : </span>
                  Skill Linkers is provided "as is" without warranties of any kind, express or implied. We do not guarantee the accuracy, reliability, or availability of our platform and services.
 
                </p>
                <p className="mt-4 home-text2">
                  <span className="home-text">Limitation of Liability: </span>
                  In no event shall Skill Linkers or its affiliates be liable for any damages arising from the use or inability to use our platform, including but not limited to direct, indirect, incidental, consequential, or punitive damages.
                </p>
               

                <p className="mt-4 home-text2">
                  <span className="home-text">Governing Law : </span>
                  These Terms of Service are governed by the laws of [Your Jurisdiction], without regard to conflict of laws principles. Any disputes arising from these terms shall be resolved through arbitration in accordance with our Dispute Resolution Policy.
If you have any questions or concerns about these Terms of Service, please contact us. Thank you for being part of Skill Linkers!
                </p>
               
              </div>
            </div>
          </Container>
        </div>
      </Container>
    </div>
  );
};



export default TermsAndConditions