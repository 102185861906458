import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const token = sessionStorage.getItem("usertoken");
  if (!token) {
    return <Navigate to="/register/login" />;
  }
  return children;
}


PrivateRoute.propTypes = {
  children: PropTypes.any,
};

export default PrivateRoute;