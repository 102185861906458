import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Navs from './components/Navbar';
import routes from './config/routes';

function App() {
  return (
    <div>
      <BrowserRouter>
      <Navs/>
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={route.component}
            exact={route.exact}
          />
        ))}

        
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
