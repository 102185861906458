// import React, { useEffect, useState, useRef } from "react";
// import Sidebar from "../../components/Sidebar";
// import "./style.css";
// import {
//   Button,
//   Card,
//   Col,
//   Container,
//   Form,
//   Image,
//   InputGroup,
//   ListGroup,
//   Navbar,
//   Row,
// } from "react-bootstrap";
// import avatar3 from "../../assets/Avatar.jpeg";
// import { IoMdSend } from "react-icons/io";
// import { io } from 'socket.io-client';
// import skillsModel from "models/skills.model";
// import { useLocation } from "react-router-dom";
// import Loader from "components/Loader";
// import MessageItem from "./MessageItem";

// const ENDPOINT = "https://sklinker.ablive.in/";
// //const ENDPOINT = "http://20.102.112.247:8080";
// //const ENDPOINT = "http://192.168.31.175:8000/";

// const Messages = () => {
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   const handleResize = () => {
//     setWindowWidth(window.innerWidth);
//   };

//   useEffect(() => {
//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const [toUser, setToUser] = useState(null);
//   const [messages, setMessages] = useState([]);
//   const [socket, setSocket] = useState(null);
//   const [textInput, setTextInput] = useState('');

//   const messagesEndRef = useRef(null);

//   const sendMessage = (e) => {
//     e.preventDefault();
//     if (socket && toUser?.email) {
//       const obj = {
//         to: toUser?.email,
//         content: textInput,
//       };
//       socket.emit("private message", obj);
//       setTextInput("");
//       console.log(obj, "sent message");
//     } else {
//       console.log("socket not working");
//     }
//   };

//   useEffect(() => {
//     const token = sessionStorage.getItem('usertoken');
//     const newSocket = io(ENDPOINT, {
//       extraHeaders: {
//         authorization: `Bearer ${token}`,
//       },
//     });

//     setSocket(newSocket);

//     newSocket.on('connect', () => {
//       console.log('Connected to the server');
//     });

//     newSocket.on('connect_error', (err) => {
//       console.error('Connection error:', err);
//     });

//     return () => {
//       newSocket.off('private message');
//       newSocket.off('userJoined');
//       newSocket.disconnect();
//     };
//   }, []);

//   useEffect(() => {
//     if (socket && toUser?.email) {
//       setMessages([]);
//       socket.emit("chat_history", { email: toUser?.email });
//       setTextInput("");
//     } else {
//       console.log("socket not working");
//     }
//     console.log("user change -> ", toUser?.email);
//   }, [toUser?.email]);

//   useEffect(() => {
//     if (socket) {
//       socket.on("private message", (newMessage) => {
//         if (newMessage?.from === toUser?.email || newMessage?.from === sessionStorage.getItem("userEmail")) {
//           setMessages((prevMessages) => [...prevMessages, newMessage]);
//         }
//         console.log("message from -> ", newMessage?.from, "selectedUser -> ", toUser?.email);
//         console.log("New message update-> ", newMessage);
//       });

//       socket.on("chat_history", (newMessage) => {
//         setMessages(newMessage);
//         console.log("message from -> ", newMessage?.from, "selectedUser -> ", toUser?.email);
//         console.log("New message list-> ", newMessage);
//       });

//       return () => {
//         socket.off();
//       };
//     }
//   }, [socket, messages]);

//   useEffect(() => {
//     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
//   }, [messages]);

//   const [isLoading, setIsLoading] = useState(true);
//   const [dataList, setDataList] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);

//   const parameters = {};
//   for (let [key, value] of searchParams) {
//     parameters[key] = value;
//   }

//   const getListData = async () => {
//     const data = {
//       email: sessionStorage.getItem("userEmail"),
//     };
//     setIsLoading(true);
//     await skillsModel.AllConnectionDetails(data)
//       .then((result) => {
//         if (result) {
//           setIsLoading(false);
//           setDataList(result?.data);
//           setCurrentPage(result?.meta?.current_page);
//         }
//       })
//       .catch((error) => {
//         setIsLoading(false);
//       });
//   };

//   useEffect(() => {
//     getListData();
//   }, [location]);

//   const [showFirstCol, setShowFirstCol] = useState(true);
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth < 700);
//     };

//     window.addEventListener('resize', handleResize);

//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   const handleBackClick = () => {
//     // Handle back click here
//     setShowFirstCol(true); // Assuming you want to show the first column on back click
//   };

//   const handleItemClick = (friendUserId) => {
//     setFriendID(friendUserId)
//     // Handle item click here
//     setShowFirstCol(false); // Assuming you want to show the second column on item click
//   };

//   return (
//     <div className="mt-10">
//       {isLoading ? (
//         <Loader />
//       ) : (
//         <Sidebar>
//           <Container fluid>
//             <Row className="p-0">
//               <Col md={3} className="left-sidebar">
//                 <h4>Messages</h4>
//                 <ListGroup variant="flush" className="p-0">
//                   {dataList.length > 0 ?
//                     dataList.map((item, index) => (
//                       <ListGroup.Item
//                         className="p-0"
//                         key={`users${index}`}
//                         onClick={() => setToUser(item)}
//                         style={{ cursor: 'pointer' }}
//                       >
//                         <div className="d-flex align-items-center">
//                           <div>
//                             <img
//                               src={item.image_path || avatar3}
//                               style={{ height: "30px", width: "30px", borderRadius: "50%", }}
//                               alt=""
//                             />
//                           </div>
//                           <div className="mt-3">
//                             <div>
//                               <span style={{ fontSize: "14px", marginLeft: "5px" }}>
//                                 {item.first_name}
//                               </span>
//                             </div>
//                             <p style={{ fontSize: "12px", marginLeft: "5px" }}>
//                               {/* Today 10:11 am */}
//                             </p>
//                           </div>
//                         </div>
//                       </ListGroup.Item>
//                     )) :
//                     <div
//                     style={{
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       flexDirection:"column"
//                     }}
//                   >
//                     <span>No Data Found</span>
//                   </div>
//                   }
//                 </ListGroup>
//               </Col>
//               <Col md={9} className="right-sidebar">
//                 {toUser && (
//                   <>
//                     <Navbar
//                       style={{ boxShadow: '0 2px 3px -1px rgba(0, 0, 0, 0.1)' }}
//                       className="bg-body-"
//                     >
//                       <div className="d-flex align-items-center mt-3 ps-1">
//                         <div>
//                           <img
//                             src={toUser?.image_path ||  avatar3}
//                             style={{ height: "30px", width: "30px" , borderRadius: "50%",}}
//                             alt=""
//                           />
//                         </div>
//                         <div className="mt-3">
//                           <div>
//                             <span style={{ fontSize: "14px", marginLeft: "5px" }}>
//                               {toUser?.first_name}
//                             </span>
//                           </div>
//                           <p
//                             style={{
//                               fontSize: "12px",
//                               marginLeft: "5px",
//                               color: "green",
//                             }}
//                           >
//                             {/* Online */}
//                           </p>
//                         </div>
//                       </div>
//                     </Navbar>
//                     <div className="m-3 pe-3 left-sidebar" >
//                       {messages &&
//                         messages.map((content, index) => (
//                           <div key={index}>
//                             <MessageItem
//                               messageItem={content?.content}
//                               messageTime={content?.time}
//                               fromEmail={content?.from}
//                               toEmail={content?.to}
//                             />
//                           </div>
//                         ))}
//                       <div ref={messagesEndRef} />
//                     </div>
//                     <Form className="chat-input ml-3 ms-1" onSubmit={sendMessage}>
//                       <Form.Control
//                         type="text"
//                         placeholder="Type your message..."
//                         id="chatInput1"
//                         name="content"
//                         value={textInput}
//                         onChange={(e) => setTextInput(e.target.value)}
//                       />
//                       <button type="submit" className="btn p-0 m-0">
//                         <IoMdSend
//                           size={30}
//                           style={{
//                             position: "absolute",
//                             right: "19px",
//                             marginTop: "-1rem",
//                           }}
//                         />
//                       </button>
//                     </Form>
//                   </>
//                 )}
//               </Col>
//             </Row>
//           </Container>
//         </Sidebar>
//       )}
//     </div>
//   );
// };

// export default Messages;

import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../../components/Sidebar";
import "./style.css";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  ListGroup,
  Navbar,
  Row,
  Spinner,
} from "react-bootstrap";
import avatar3 from "../../assets/Avatar.jpeg";
import { IoMdSend } from "react-icons/io";
import { io } from "socket.io-client";
import skillsModel from "models/skills.model";
import { useLocation } from "react-router-dom";
import Loader from "components/Loader";
import MessageItem from "./MessageItem";
import { Input } from "reactstrap";

const ENDPOINT = "https://api.skilllinkers.com/";
//const ENDPOINT = "http://20.102.112.247:8080";
//const ENDPOINT = "http://192.168.31.175:8000/";

const Messages = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [toUser, setToUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const [textInput, setTextInput] = useState("");

  const messagesEndRef = useRef(null);

  const sendMessage = (e) => {
    e.preventDefault();
    if (socket && toUser?.email) {
      const obj = {
        to: toUser?.email,
        content: textInput,
      };
      socket.emit("private message", obj);
      setTextInput("");
      console.log(obj, "sent message");
    } else {
      console.log("socket not working");
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("usertoken");
    const newSocket = io(ENDPOINT, {
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
    });

    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("Connected to the server");
    });

    newSocket.on("connect_error", (err) => {
      console.error("Connection error:", err);
    });

    return () => {
      newSocket.off("private message");
      newSocket.off("userJoined");
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket && toUser?.email) {
      setMessages([]);
      socket.emit("chat_history", { email: toUser?.email });
      setTextInput("");
    } else {
      console.log("socket not working");
    }
    console.log("user change -> ", toUser?.email);
  }, [toUser?.email]);

  useEffect(() => {
    if (socket) {
      socket.on("private message", (newMessage) => {
        if (
          newMessage?.from === toUser?.email ||
          newMessage?.from === sessionStorage.getItem("userEmail")
        ) {
          setMessages((prevMessages) => [...prevMessages, newMessage]);
        }
        console.log(
          "message from -> ",
          newMessage?.from,
          "selectedUser -> ",
          toUser?.email
        );
        console.log("New message update-> ", newMessage);
      });

      socket.on("chat_history", (newMessage) => {
        setMessages(newMessage);
        console.log(
          "message from -> ",
          newMessage?.from,
          "selectedUser -> ",
          toUser?.email
        );
        console.log("New message list-> ", newMessage);
      });

      return () => {
        socket.off();
      };
    }
  }, [socket, messages]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const parameters = {};
  for (let [key, value] of searchParams) {
    parameters[key] = value;
  }

  const getListData = async () => {
    const data = {
      email: sessionStorage.getItem("userEmail"),
    };
    setIsLoading(true);
    await skillsModel
      .AllConnectionDetails(data)
      .then((result) => {
        if (result) {
          setIsLoading(false);
          setDataList(result?.data);
          setCurrentPage(result?.meta?.current_page);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getListData();
  }, [location]);

  const [showFirstCol, setShowFirstCol] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleBackClick = () => {
    setShowFirstCol(true);
  };

  const handleItemClick = (item) => {
    setToUser(item);
    setShowFirstCol(false);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [messageUserList, setmessageUserList] = useState([]);

  // Function to handle search
  const handleSearch = (term) => {
    const searchInput = term.toLowerCase();
    let map1SearchResults = dataList?.filter((item) =>
      item?.first_name.toLowerCase().includes(searchInput)
    );
    // Update state with results
    setmessageUserList(map1SearchResults);
  };

  // Use effect to perform search whenever searchTerm changes
  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm,dataList]);

  return (
    <div className="mt-10">
      <Sidebar>
        <Container fluid>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#01A8CC",
              }}
            >
              <Spinner></Spinner>
            </div>
          ) : (
            <Row className="p-0">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center", // Align items vertically in the center
                  justifyContent: "space-between", // Space out items evenly
                  boxShadow: "0 2px 3px -1px rgba(0, 0, 0, 0.1)",
                  padding: "10px", // Optional: Add padding for better spacing
                }}
              >
                <div>
                  <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Message
                  </span>
                </div>
              </div>

              {(showFirstCol || !isMobile) && (
                <Col md={3} className="left-sidebar mt-3 ">
                  <div className="input-container">
                    <i className="fa-solid fa-magnifying-glass search-icon"></i>
                    <input
                      className="search-input"
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search"
                    />
                  </div>{" "}
                  <ListGroup variant="flush" className="p-0">
                    {dataList?.length > 0 ? (
                      messageUserList?.map((item, index) => (
                        <ListGroup.Item
                          className="p-0"
                          key={`users${index}`}
                          onClick={() => handleItemClick(item)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="d-flex align-items-center">
                            <div>
                              <img
                                src={item.image_path || avatar3}
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  borderRadius: "50%",
                                }}
                                alt=""
                              />
                            </div>
                            <div className="mt-3">
                              <div>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {item.first_name}
                                </span>
                              </div>
                              <p
                                style={{ fontSize: "12px", marginLeft: "5px" }}
                              >
                                {/* Today 10:11 am */}
                              </p>
                            </div>
                          </div>
                        </ListGroup.Item>
                      ))
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <span>No Data Found</span>
                      </div>
                    )}
                  </ListGroup>
                </Col>
              )}
              {(!showFirstCol || !isMobile) && (
                <Col md={9} className="right-sidebar">
                  {isMobile && !showFirstCol && (
                    <Button variant="link" onClick={handleBackClick}>
                      <i class="fa-solid fa-arrow-left"></i>
                    </Button>
                  )}
                  {toUser && (
                    <>
                      <Navbar
                        style={{
                          boxShadow: "0 2px 3px -1px rgba(0, 0, 0, 0.1)",
                        }}
                        className="bg-body-"
                      >
                        <div className="d-flex align-items-center mt-1 ps-1">
                          <div>
                            <img
                              src={toUser?.image_path || avatar3}
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                              }}
                              alt=""
                            />
                          </div>
                          <div className="mt-3">
                            <div>
                              <span
                                style={{ fontSize: "14px", marginLeft: "5px" }}
                              >
                                {toUser?.first_name}
                              </span>
                            </div>
                            <p
                              style={{
                                fontSize: "12px",
                                marginLeft: "5px",
                                color: "green",
                              }}
                            >
                              {/* Online */}
                            </p>
                          </div>
                        </div>
                      </Navbar>
                      <div className="m-3 pe-3 left-sidebar">
                        {messages &&
                          messages.map((content, index) => (
                            <div key={index}>
                              <MessageItem
                                messageItem={content?.content}
                                messageTime={content?.time}
                                fromEmail={content?.from}
                                toEmail={content?.to}
                              />
                            </div>
                          ))}
                        <div ref={messagesEndRef} />
                      </div>
                      <Form className="chat-input  ms-1" onSubmit={sendMessage}>
                        <Form.Control
                          type="text"
                          placeholder="Type your message..."
                          id="chatInput1"
                          name="content"
                          value={textInput}
                          onChange={(e) => setTextInput(e.target.value)}
                        />
                        <button type="submit" className="btn p-0 m-0">
                          <IoMdSend
                            size={30}
                            style={{
                              position: "absolute",
                              right: "40px",
                              marginTop: "-1rem",
                            }}
                          />
                        </button>
                      </Form>
                    </>
                  )}
                </Col>
              )}
            </Row>
          )}
        </Container>
      </Sidebar>
    </div>
  );
};

export default Messages;
