import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import styles from "./style.module.css";
import logo from "../../assets/logo.svg";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  ListGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import avatar3 from "../../assets/Avatar.jpeg";
import { MdOutlinePersonOutline } from "react-icons/md";
import { FiHome } from "react-icons/fi";
import { FiZap } from "react-icons/fi";
import { MdLogout } from "react-icons/md";
import { FiMinusCircle } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { SiPluscodes } from "react-icons/si";
import AddSkills from "./AddSkills";
import AddNewSkills from "./AddNewSkills";
import AddNewPost from "./AddNewPost";
import SkillsModel from "models/skills.model";
import { helper } from "lib/helper";
import AddAbout from "./AddAbout";
import { AiFillEdit } from "react-icons/ai";
import { useParams } from "react-router-dom";
import skillsModel from "models/skills.model";
import ProfileUpdate from "./ProfileUpdate";
import ViewProfile from "./ViewProfile";

const Profile = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [addSkills, setAddSkills] = useState(false);
  const [addNewSkills, setAddNewSkills] = useState(false);
  const [addpost, setAddPost] = useState(false);
  const [addAbout, setAddAbout] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [selectedImage, setSelectedImage] = useState(null);

  const handleEditClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      // handleSubmit()
    };

    input.click();
  };

  const [userData, setUserData] = useState([]);
  const [loading, setLoadign] = useState(false);
  const getListDataUser = async (id) => {
    setLoadign(true);
    const data = {
      email: sessionStorage.getItem("userEmail"),
    };
    await SkillsModel.UserData(data)
      .then((result) => {
        if (result) {
          setUserData(result.data[0]);
          setLoadign(false);
        }
      })
      .catch((error) => {
        setLoadign(false);
        // helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  useEffect(() => {
    getListDataUser();
  }, []);


  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("email", userData?.email);
    form.append("school_name", userData?.school_name);
    form.append("grade_level", userData?.grade_level);
    form.append("skills", userData?.skill);
    form.append("profile", selectedImage);
    form.append("about", userData?.user_bio);

    // You can adjust the header configuration according to your API requirements
    skillsModel
      .updateUserNew(form)
      .then((response) => {
        helper.sweetalert.toast(response.data.msg, "success");
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  

  return (
    <div>
      <Sidebar>
        <Container>
          {loading === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color:"#01A8CC"
              }}
            >
              <Spinner></Spinner>
            </div>
          ) : (
            <div>
              <Card className={styles.profilebg}>
                  <div className={`${styles.imageStack}  mt-3`}>
                   
                      <div
                        style={{
                          position: "relative",
                          textAlign: "center",
                          marginBottom: "10px",
                        }}
                        className={`${styles.avatarcontainer}`}
                      >
                        <img
                          src={userData?.image_path || avatar3}
                          alt="Back Avatar"
                          className={`${styles.avatar} ${styles.front}`}
                          
                        />
                      </div>
                  
                  </div>
                
              </Card>
              {/* <Container> */}
              <Row>
                <Col xs={5} lg={4} md={5} xl={2} sm={7}></Col>
                <Col>
                  <div>
                    <h3
                      style={
                        windowWidth <= 1367
                          ? { paddingLeft: "18px", marginLeft: "20px" }
                          : { paddingLeft: "0px" }
                      }
                    >
                      {userData && userData?.first_name}
                    </h3>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p
                        style={
                          windowWidth <= 1367
                            ? {
                                paddingLeft: "18px",
                                marginLeft: "20px",
                                fontWeight: "bold",
                              }
                            : { paddingLeft: "0px" }
                        }
                      >
                        {userData && userData?.connection_count} Connections
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>


              <Row>
                <Col mb={10} lg={9}>
                  <Button
                    // size="sm"
                    className={styles.newPostBtn}
                    onClick={handleShow}
                    style={{ marginTop: "30px" }}
                  >
                    <AiFillEdit color="#01A8CC" />
                    <> </>Edit Profile
                  </Button>

                  <Button
                    // size="sm"
                    className={styles.newPostBtn}
                    onClick={handleShow1}
                    style={
                      windowWidth <= 730
                        ? {  marginTop: "30px" }
                        : { marginTop: "30px",marginLeft:"10px" }
                    }
                    
                  >
                    {/* <AiFillEdit color="#01A8CC" /> */}
                    <i class="fa-solid fa-eye"></i>
                    <> </>View Profile
                  </Button>
                </Col>

                <Col mb={3}>
                  <Button
                    onClick={() => {
                      setAddPost(true);
                    }}
                    style={{ marginTop: "30px" }}
                    // size="sm"
                    className={styles.newPostBtn}
                  >
                    <FaPlus color="#01A8CC" />
                    <> </>Add Post
                  </Button>
                </Col>
              </Row>
              <Row>
                <div style={{ marginTop: "10px" }}>
                  <AddAbout
                    userData={userData}
                    getListDataUser={() => getListDataUser()}
                  />
                </div>
              </Row>
              {/* </Container */}

              <AddSkills userData={userData} />

              <AddNewSkills userData={userData} />
            </div>
          )}
        </Container>
      </Sidebar>
      <AddNewPost show={addpost} onHide={() => setAddPost(false)} />
      <ProfileUpdate
        show={show}
        onHide={handleClose}
        data={userData}
        getListDataUser={() => getListDataUser()}
      />

      <ViewProfile
        show={show1}
        onHide={handleClose1}
        data={userData}
        getListDataUser={() => getListDataUser()}
      />
      {/* <AddAbout show={addAbout} onHide={() => setAddAbout(false)} userData={userData}  /> */}
    </div>
  );
};

export default Profile;
