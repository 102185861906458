import React from "react";

const MessageItem = ({messageItem, messageTime, fromEmail="", toEmail=""}) => {
    const loggedEmail = sessionStorage.getItem("userEmail");
   
    return (
        <>
            <div className={`d-flex flex-row ${loggedEmail == fromEmail ? 'justify-content-end':'justify-content-start'}  mb-4`}>
                {/* <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                    alt="avatar 1"
                    style={{ width: "45px", height: "100%" }}
                /> */}
                <div
                    className="p-3 ms-3"
                    style={{
                        borderRadius: "15px",
                        backgroundColor: loggedEmail == fromEmail ? 'rgba(50, 12, 237,.2)':"rgba(57, 192, 237,.2)"
                    }}
                    
                >
                    <p className="small mb-0" >
                        {messageItem}
                    </p>
                    <span style={{fontSize:'10px', color:'#5f6164'}}>{messageTime}</span>
                </div>
            </div>
        </>
    );
};

export default MessageItem;