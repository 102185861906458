import axiosInstance from "../lib/axiosInstance";

const resetPasswordModal = class {
  constructor() {
    this.baseUrl = "/";
  }

  // async list(data = []) {
  //   return (
  //     await axiosInstance.post(`${this.baseUrl}/skillCategory`)
  //   ).data;
  // }

  async SendOtp(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`user/sendOtp`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }

  async ValidatedOpt(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`user/validatedOpt`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }


 
  async resetPassowrd(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`user/setPassowrd`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }


 




};

export default new resetPasswordModal();
