import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  CardGroup,
  Card,
  InputGroup,
} from "react-bootstrap";
import "./style.css";
import homepage from "../../assets/homepage.svg";
import backHome from "../../assets/Vector.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SignUpPopUp from "./SignUpPopUp";
import shutterstock from "../../assets/shutterstock.svg";
import UserModel from "../../models/users.model";
import { helper } from "../../lib/helper";
import Spinner from "react-bootstrap/Spinner";

function SignUp() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("login");
  const [isLogin, setIsLogin] = useState(true);
  const [isProfileComplete, setIsProfileComplete] = React.useState(false);
  const [addsLoadig, setAddsLoadig] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [uid, setUid] = useState("");

  // const navigate = useNavigate();
  const parmas = useParams();

  const location = useLocation();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    setActiveTab(parmas.id);
  }, [parmas.id]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const form = new FormData(event.target);
    const data = {
      first_name: form.get("first_name"),
      email: form.get("email"),
      password: form.get("password"),
    };

    if (!form.get("first_name")) {
      helper.sweetalert.toast("Email is Required", "error");
    } else if (!form.get("email")) {
      helper.sweetalert.toast("Password is Required", "error");
    } else if (!form.get("password")) {
      helper.sweetalert.toast("Password is Required", "error");
    } else {
      UserModel.create(data)
        .then((response) => {
          console.log(response, "signupin");
          if (response.msg == "User created successfully.") {
            sessionStorage.setItem("userEmail", response.data);
            handleShow(true);
            setUid("signup");
            setData(response.data);
            helper.sweetalert.toast(response.msg, "success");
          } else {
            helper.sweetalert.toast(response.msg, "error");
          }
          setLoading(false);

          // navigate("/");
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const handleLogin = (event) => {
    setAddsLoadig(true);
    event.preventDefault();
    const form = new FormData(event.target);

    const emailLogin = form.get("email");
    const passwordLogin = form.get("password");

    const data = {
      email: emailLogin,
      password: passwordLogin,
    };

    if (!emailLogin) {
      helper.sweetalert.toast("Email is Required", "error");
    } else if (!passwordLogin) {
      helper.sweetalert.toast("Password is Required", "error");
    } else {
      UserModel.login(data)
        .then((response) => {
          console.log(response, "response");

          if (response.msg == "Successfully Login") {
            sessionStorage.setItem("userinfo", JSON.stringify(response));
            sessionStorage.setItem("userEmail", response.email);
            sessionStorage.setItem("userId", response.id);
            sessionStorage.setItem("username", response.name);
            sessionStorage.setItem("userrole", response.role);
            sessionStorage.setItem("usertoken", response.token);
            if (response.is_active == false) {
              setUid("login");
              handleShow(true);
            }
            if (response.is_active == true) {
              navigate("/profile");
              helper.sweetalert.toast(response.msg, "success");
            }
          } else {
            helper.sweetalert.toast(response.msg, "error");
          }
          setAddsLoadig(false);
        })
        .catch((error) => {
          setAddsLoadig(false);
          console.error(error);
        });
    }

    // Proceed with login if no errors
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} lg={10} xl={10}>
          <CardGroup>
            <Card>
              <header style={{ backgroundImage: `url(${shutterstock})` }}>
                <div className="color-overlay ">
                  <div
                    onClick={() => navigate("/")}
                    className="back-home mt-3 backBtn d-flex align-items-center justify-content-between gap-2"
                  >
                    {" "}
                    {/* <div className="mb-1">
                      <img
                        style={{ height: "14.17px", width: "13.46px" }}
                        src={backHome}
                      />
                    </div> */}
                    <div
                      className="mt-0 backtext fontWeight400 "
                      style={{
                        width: "60px",
                        fontSize: "30px",
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <i class="fa-solid fa-arrow-left"></i>
                    </div>
                  </div>
                </div>
              </header>
            </Card>
            <Card className="form-style loginSignup">
              <div className="container-fluid " style={{ padding: "10px" }}>
                <Container>
                  {" "}
                  <div>
                    <h3>
                      Welcome to{" "}
                      <b className="seprate-heading">Skill Linkers?</b>
                    </h3>
                    <p>
                      Login or Sign Up to access and manage all your skills.
                    </p>
                  </div>
                </Container>
                <Container fluid>
                  <div className="mt-3">
                    <Container
                      style={{ minWidth: "324px", marginLeft: "10px" }}
                    >
                      <Row style={{ flexDirection: "row", display: "flex" }}>
                        <Col mb={12}>
                          <ul className="nav nav-tabs">
                            <li className="nav-item">
                              <span
                                className={`nav-link ${
                                  activeTab === "login" ? "active" : "inactive"
                                }`}
                                onClick={() => {
                                  handleTabClick("login");
                                  setIsLogin(true);
                                }}
                              >
                                Login
                              </span>
                            </li>
                            <li className="nav-item">
                              <span
                                className={`nav-link ${
                                  activeTab === "signup" ? "active" : "inactive"
                                }`}
                                onClick={() => {
                                  handleTabClick("signup");
                                  setIsLogin(false);
                                }}
                              >
                                Signup
                              </span>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Container>
                    <Container>
                      <div className="tab-content mt-2">
                        <div
                          className={`tab-pane fade ${
                            activeTab === "login" ? "show active" : ""
                          }`}
                          id="login"
                        >
                          {/* Login Form */}
                          <div className="mt-3">
                            <Row>
                              <Col xs={12} lg={9} md={9} xl={11}>
                                <Form onSubmit={handleLogin}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>School Domain Email</Form.Label>
                                    <Form.Control
                                      isInvalid={!!formErrors.email}
                                      name="email"
                                      className="linearBorder"
                                      size="lg"
                                      type="email"
                                      placeholder="Enter Email"
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formErrors.emailLogin}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  {/* <Form.Group
                                    className="mb-3"
                                    controlId="formBasicPassword"
                                  >
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                      isInvalid={formErrors.password}
                                      name="password"
                                      className="linearBorder"
                                      size="lg"
                                      type="password"
                                      placeholder="Password"
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formErrors.passwordLogin}
                                    </Form.Control.Feedback>
                                  </Form.Group> */}

<Form.Group className="mb-3" controlId="formBasicPassword">
      <Form.Label>Password</Form.Label>
      <InputGroup     className="linearBorder" >
        <Form.Control
          isInvalid={!!formErrors.password}
          name="password"
      
          size="lg"
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          required
        />
        <InputGroup.Text className="toggle-password" onClick={togglePasswordVisibility}>
          <i className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
        </InputGroup.Text>
        <Form.Control.Feedback type="invalid">
          {formErrors.passwordLogin}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
                                  <div className="d-grid gap-2">
                                    <Button
                                      type="submit"
                                      className="linearBg"
                                      variant="secondary"
                                      size="lg"
                                    >
                                      {addsLoadig ? (
                                        <Spinner animation="border" />
                                      ) : (
                                        <span>Log In & Continue</span>
                                      )}
                                    </Button>
                                  </div>
                                  <div className="d-flex justify-content-between mt-3">
                                    <div></div>
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => navigate("/emailpage")}
                                    >
                                      Forgot Password?
                                    </div>
                                  </div>
                                </Form>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade ${
                            activeTab === "signup" ? "show active" : ""
                          }`}
                          id="signup"
                        >
                          {/* Signup Form */}
                          <div className="mt-3">
                            <Row>
                              <Col xs={12} lg={9} md={9} xl={11}>
                                <Form onSubmit={handleSubmit}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>Your Name</Form.Label>
                                    <Form.Control
                                      isInvalid={!!formErrors.name}
                                      name="first_name"
                                      className="linearBorder"
                                      size="lg"
                                      placeholder="Enter Name"
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formErrors.name}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>School Domain Email</Form.Label>
                                    <Form.Control
                                      isInvalid={!!formErrors.email}
                                      name="email"
                                      className="linearBorder"
                                      size="lg"
                                      placeholder="Enter Email"
                                      required
                                      type="email"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formErrors.email}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  {/* <Form.Group
                                    className="mb-3"
                                    controlId="formBasicPassword"
                                  >
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                      isInvalid={!!formErrors.password}
                                      name="password"
                                      className="linearBorder"
                                      size="lg"
                                      type="password"
                                      placeholder="Password"
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formErrors.password}
                                    </Form.Control.Feedback>
                                  </Form.Group> */}

<Form.Group className="mb-3" controlId="formBasicPassword">
      <Form.Label>Password</Form.Label>
      <InputGroup     className="linearBorder" >
        <Form.Control
          isInvalid={!!formErrors.password}
          name="password"
      
          size="lg"
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          required
        />
        <InputGroup.Text className="toggle-password" onClick={togglePasswordVisibility}>
          <i className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
        </InputGroup.Text>
        <Form.Control.Feedback type="invalid">
          {formErrors.passwordLogin}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>

                                  <div className="d-flex justify-content-between mt-2 mb-2">
                                    <div>
                                      {" "}
                                      <Form.Check
                                        aria-label="option 1"
                                        required
                                        style={{
                                          fontSize: "20px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    </div>
                                    <div>
                                      Please review and accept our {"  "}
                                      <Link to={"/termsandconditions"}>
                                        Terms and Conditions{" "}
                                      </Link>
                                      to proceed.
                                    </div>
                                  </div>
                                  <div className="d-grid gap-2">
                                    <Button
                                      type="submit"
                                      className="linearBg"
                                      variant="secondary"
                                      size="lg"
                                    >
                                      {loading ? (
                                        <Spinner animation="border" />
                                      ) : (
                                        <span>Sign Up & Continue</span>
                                      )}
                                    </Button>
                                  </div>
                                </Form>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Container>
                  </div>
                </Container>
              </div>
            </Card>
          </CardGroup>
        </Col>
      </Row>
      <SignUpPopUp
        show={show}
        onHide={handleClose}
        data={data}
        setActiveTab={setActiveTab}
        uid={uid}
      />
    </Container>
  );
}

export default SignUp;
