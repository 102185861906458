import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
// import styles from './style.module.css';
import styles from "./style.module.css";
import logo from "../../assets/logo.svg";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  ListGroup,
  Modal,
  Navbar,
  Row,
  Spinner,
} from "react-bootstrap";
import avatar3 from "../../assets/Avatar.jpeg";
import { MdOutlinePersonOutline } from "react-icons/md";
import { FiHome } from "react-icons/fi";
import { FiZap } from "react-icons/fi";
import { MdLogout } from "react-icons/md";
import { FiMinusCircle } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { SiPluscodes } from "react-icons/si";
import { IoMdSend } from "react-icons/io";
import shutterstock from "../../assets/shutterstock.svg";
import SkillsModel from "models/skills.model";
import { helper } from "lib/helper";
import AddNewPost from "pages/Profile/AddNewPost";
import skillsModel from "models/skills.model";
import media from "../../assets/media.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "reactstrap";

const Suggestion = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  const [addpost, setAddPost] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [addsLoadig, setAddsLoadig] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);

  // Function to handle image selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  const handleSubmit = (event) => {
    setAddsLoadig(true);
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("student_id", sessionStorage.getItem("userid"));
    // You can adjust the header configuration according to your API requirements
    skillsModel
      .adduserPost(form)
      .then((response) => {
        setSelectedImage("");
        handleClose();
        helper.sweetalert.toast(response.data.msg, "success");
        setAddsLoadig(false);
      })
      .catch((error) => {
        setAddsLoadig(false);
        helper.sweetalert.toast(error.response.data.media[0], "error");
      });
  };

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [openIndex, setOpenIndex] = useState(-1);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const parameters = {};
  for (let [key, value] of searchParams) {
    parameters[key] = value;
  }

  const [OldSkills, setOldSkills] = useState([]);
  const getListDataOldSkills = async () => {
    const data = {
      email: sessionStorage.getItem("userEmail"),
      skillType: "old",
    };
    await SkillsModel.oldSkill(data)
      .then((result) => {
        if (result) {
          setOldSkills(result?.data);
        }
      })
      .catch((error) => {
        setOldSkills([]);
        // helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  useEffect(() => {
    getListDataOldSkills();
  }, []);

  const oldSkillsDataget = OldSkills && OldSkills?.map((item) => item.skill_id);
  const [oldSkillMatch, setOldSkillMatch] = useState([]);
  const getListDataOld = async () => {
    setIsLoading(true);
    const data = {
      email: sessionStorage.getItem("userEmail"),
      skill: oldSkillsDataget,
      skill_type: "old",
    };

    await skillsModel
      .AllUserData(data)
      .then((result) => {
        if (result) {
          setIsLoading(false);
          setOldSkillMatch(result?.data);
          setCurrentPage(result?.meta?.current_page);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {}, [location]);

  const [newSkills, setNewSkills] = useState([]);
  const getListDataNewSkills = async () => {
    const data = {
      email: sessionStorage.getItem("userEmail"),
      skillType: "new",
    };
    await SkillsModel.oldSkill(data)
      .then((result) => {
        if (result) {
          setNewSkills(result?.data);
        }
      })
      .catch((error) => {
        // helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };
  useEffect(() => {
    getListDataNewSkills();
  }, []);

  const newSkillsDataget =
    newSkills && newSkills?.map((item) => item?.skill_id);

  console.log(newSkillsDataget, "newSkills");

  const [newSkillMatch, setNewSkillMatch] = useState([]);

  const getListDataNew = async () => {
    setIsLoading(true);
    const data = {
      email: sessionStorage.getItem("userEmail"),
      skill: newSkillsDataget,
      skill_type: "new",
    };

    await skillsModel
      .AllUserData(data)
      .then((result) => {
        if (result) {
          setIsLoading(false);
          setNewSkillMatch(result?.data);
          setCurrentPage(result?.meta?.current_page);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (OldSkills?.length > 0) {
      getListDataOld();
    }

    if (newSkills.length > 0) {
      getListDataNew();
    }
  }, [OldSkills, newSkills]);

  const handleSubmitConnection = (email) => {
    const data = {
      email: sessionStorage.getItem("userEmail"),
      sugestionEmail: email,
    };

    skillsModel
      .addConnection(data)
      .then((response) => {
        navigate("/messages");
        console.log(response, "likepost");
        helper.sweetalert.toast(response.msg, "success");
        setAddsLoadig(false);
      })
      .catch((error) => {
        setAddsLoadig(false);
      });
  };

  // State for search term and results
  const [searchTerm, setSearchTerm] = useState("");
  const [map1Results, setMap1Results] = useState([]);
  const [map2Results, setMap2Results] = useState([]);

  // Function to handle search
  const handleSearch = (term) => {
    const searchInput = term.toLowerCase();
    let map1SearchResults = oldSkillMatch?.filter((item) =>
      item?.first_name.toLowerCase().includes(searchInput)
    );
    let map2SearchResults = newSkillMatch?.filter((item) =>
      item?.first_name.toLowerCase().includes(searchInput)
    );

    // Update state with results
    setMap1Results(map1SearchResults);
    setMap2Results(map2SearchResults);
  };

  // Use effect to perform search whenever searchTerm changes
  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm,newSkillMatch,oldSkillMatch]);

  return (
    <div>
      <Sidebar>
        <Container fluid>
          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center", // Align items vertically in the center
                justifyContent: "space-between", // Space out items evenly
                boxShadow: "0 2px 3px -1px rgba(0, 0, 0, 0.1)",
                padding: "10px", // Optional: Add padding for better spacing
              }}
            >
              <div>
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Suggestion
                </span>
              </div>
              <div style={{ width: "30%" }}>
                <div className="input-container">
                  <i className="fa-solid fa-magnifying-glass search-icon"></i>
                  <input
                    className="search-input"
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search"
                  />
                </div>{" "}
              </div>
            </div>

            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                  color: "#01A8CC",
                }}
              >
                <Spinner></Spinner>
              </div>
            ) : newSkillMatch?.length > 0 || oldSkillMatch?.length > 0 ? (
              <div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center", // Align items vertically in the center
                      justifyContent: "center", // Space out items evenly
                      boxShadow: "0 2px 3px -1px rgba(0, 0, 0, 0.1)",
                      padding: "10px", // Optional: Add padding for better spacing
                    }}
                    className="mt-3"
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Want to build new skills
                    </span>
                  </div>
                  <Row style={{ display: "flex", flexDirection: "row" }}>
                    {map2Results &&
                      map2Results?.map((item, index) => {
                        return (
                          <Col
                            md={4}
                            className={`${styles.leftSidebar}`}
                            key={index}
                          >
                            <ListGroup
                              variant="flush"
                              className="shadow-sm p-3  bg-white rounded mt-4"
                              style={{
                                borderTop: "1px solid lightgray",
                                height: "92%",
                                padding: 0,
                                marginLeft: "10px",
                              }}
                            >
                              <Row className="d-flex ">
                                <Col md={2}>
                                  <img
                                    src={item.image_path || avatar3}
                                    style={{
                                      height: "30px",
                                      width: "30px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </Col>
                                <Col
                                  md={10}
                                  style={{
                                    fontWeight: "bold",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <div style={{ fontWeight: "bold" }}>
                                    {item?.first_name}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <div
                                    style={{
                                      padding: "4px",
                                      marginTop: "4px",
                                      // height: "27vh",
                                    }}
                                    // className="shadow-sm p-2  bg-white rounded"
                                  >
                                    <div style={{ fontWeight: 700 }}>
                                      Matched user's skills
                                    </div>

                                    {/* <div>
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        marginLeft: "13px",
                                      }}
                                    >
                                      Similer skills
                                    </div>
                                    {item?.skills
                                      ?.slice(0, 2)
                                      .map((data, index) => {
                                        return (
                                          <div key={index}>
                                            <div style={{ marginLeft: "20px" }}>
                                              {data.skill}
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div> */}

                                    <div>
                                      {/* <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        marginLeft: "13px",
                                      }}
                                    >
                                      Want to build new skills
                                    </div> */}
                                      {item?.skills
                                        ?.slice(0, 2)
                                        .map((data, index) => {
                                          return (
                                            <div key={index}>
                                              <div
                                                style={{ marginLeft: "20px" }}
                                              >
                                                {data.skill}
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      className={`${styles.likeBtn}`}
                                      size="sm"
                                      onClick={() =>
                                        handleSubmitConnection(item?.email)
                                      }
                                      style={{
                                        marginTop: "5px",
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        height: "40px",
                                        width: "40%",
                                        fontSize: "20px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Messages
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </ListGroup>
                          </Col>
                        );
                      })}
                  </Row>
                </div>

                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center", // Align items vertically in the center
                      justifyContent: "center", // Space out items evenly
                      boxShadow: "0 2px 3px -1px rgba(0, 0, 0, 0.1)",
                      padding: "10px", // Optional: Add padding for better spacing
                    }}
                    className="mt-3"
                  >
                    <span style={{ fontWeight: "bold" }}>Similer skills</span>
                  </div>
                  <Row style={{ display: "flex", flexDirection: "row" }}>
                    {map1Results &&
                      map1Results?.map((item, index) => {
                        return (
                          <Col
                            md={4}
                            className={`${styles.leftSidebar}`}
                            key={index}
                          >
                            <ListGroup
                              variant="flush"
                              className="shadow-sm p-3  bg-white rounded mt-4"
                              style={{
                                borderTop: "1px solid lightgray",
                                height: "92%",
                                padding: 0,
                                marginLeft: "10px",
                              }}
                            >
                              <Row className="d-flex ">
                                <Col md={2}>
                                  <img
                                    src={item.image_path || avatar3}
                                    style={{
                                      height: "30px",
                                      width: "30px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </Col>
                                <Col
                                  md={10}
                                  style={{
                                    fontWeight: "bold",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <div style={{ fontWeight: "bold" }}>
                                    {item?.first_name}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <div
                                    style={{
                                      padding: "4px",
                                      marginTop: "4px",
                                      // height: "27vh",
                                    }}
                                    // className="shadow-sm p-2  bg-white rounded"
                                  >
                                    <div style={{ fontWeight: 700 }}>
                                      Matched user's skills
                                    </div>

                                    <div>
                                      {/* <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                      marginLeft: "13px",
                                    }}
                                  >
                                    Similer skills
                                  </div> */}
                                      {item?.skills
                                        ?.slice(0, 2)
                                        .map((data, index) => {
                                          return (
                                            <div key={index}>
                                              <div
                                                style={{ marginLeft: "20px" }}
                                              >
                                                {data.skill}
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>

                                    {/* <div>
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                      marginLeft: "13px",
                                    }}
                                  >
                                    Want to build new skills
                                  </div>
                                  {item?.skills
                                    ?.slice(0, 2)
                                    .map((data, index) => {
                                      return (
                                        <div key={index}>
                                          <div style={{ marginLeft: "20px" }}>
                                            {data.skill}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div> */}
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      className={`${styles.likeBtn}`}
                                      size="sm"
                                      onClick={() =>
                                        handleSubmitConnection(item?.email)
                                      }
                                      style={{
                                        marginTop: "5px",
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        height: "40px",
                                        width: "40%",
                                        fontSize: "20px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Messages
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </ListGroup>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>No Data Found</span>
              </div>
            )}
          </Row>
        </Container>
      </Sidebar>

      {/* <AddNewPost show={addpost} onHide={() => setAddPost(false)} /> */}

      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Title
          style={{ padding: "15px" }}
          id="contained-modal-title-vcenter"
        >
          New Post
        </Modal.Title>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className={styles.postInputLabel}>
                Post Title
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Post Title"
                name="post_name"
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className={styles.postInputLabel}>
                Post Description
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Post Description"
                name="about"
              />
            </Form.Group>
            <div className="d-grid gap-2 mt-5">
              <div className="d-flex justify-content-between mt-5">
                <div className="mt-2">
                  <label htmlFor="mediaInput">
                    <img
                      src={selectedImage || media}
                      alt="Selected Image"
                      style={{ height: "20px", width: "20px" }}
                    />
                    <span>Add media</span>
                  </label>
                  <input
                    id="mediaInput"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    name="media"
                  />
                </div>
                <div>
                  <Button
                    className={`linearBg ${styles.postBtn}`}
                    variant="secondary"
                    size="lg"
                    type="submit"
                  >
                    Post now
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Suggestion;

const data = {
  email: "test@gmail.com",
  data: "",
  skillType: "old",
  skilss: [2, 4, 5],
};
