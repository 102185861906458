import React, { useState, useEffect } from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../Registration/style.css";
import avatar3 from "../../assets/profile.svg";
import skillsModel from "models/skills.model";
import { helper } from "lib/helper";
import { useLocation, useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Spinner from "react-bootstrap/Spinner";

const ViewProfile = (props) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [addsLoadig, setAddsLoadig] = useState(false);
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
    }
  };

  console.log(props.data, "profileupdate");
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [perPage, setPerPage] = useState(20);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [postDescription, setPostDescription] = useState("");

  useEffect(() => {
    setPostDescription(props?.data?.user_bio);
  }, [props?.data?.user_bio]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPostDescription(value);
  };

  const remainingCharacters = 1000 - postDescription?.length;

  const parameters = {};
  for (let [key, value] of searchParams) {
    parameters[key] = value;
  }

  const getListData = async () => {
    setIsLoading(true);
    const data = {
      email: sessionStorage.getItem("userEmail"),
    };
    await skillsModel
      .list(data)

      .then((result) => {
        setIsLoading(false);
        if (result) {
          console.log(result, "result");
          setDataList(result.data);
          setCurrentPage(result?.meta?.current_page);
          setPerPage(result?.meta?.per_page);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        // helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  useEffect(() => {
    getListData();
  }, []);

  const [subSkills, setSubSkills] = useState("");
  const getListDataSubSkills = async (id) => {
    const data = {
      categoryId: parseInt(id),
    };
    await skillsModel
      .skillNameBycategoryId(data)
      .then((result) => {
        setIsLoading(false);
        if (result) {
          setSubSkills(result?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  const [selectedValues, setSelectedValues] = useState([]);
  const subSkillsid = selectedValues && selectedValues?.map((item) => item.id);

  console.log(selectedValues, "selectedValues");
  const [schoolName, setSchoolName] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");

  useEffect(() => {
    setSelectedValues(props?.data?.skill);
    setGradeLevel(props?.data?.grade_level);
    setSelectedImage(props?.data?.image_path);
  }, [props?.data?.skill, props?.data?.grade_level]);

  const handleSubmit = (event) => {
    setAddsLoadig(true);
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("email", props?.data?.email);
    form.append("grade_level", gradeLevel);
    form.append("skills", props?.data?.skill);
    skillsModel
      .updateUserNew(form)
      .then((response) => {
        props.onHide();
        props.getListDataUser();
        helper.sweetalert.toast(response.data.msg, "success");
        setAddsLoadig(false);
      })
      .catch((error) => {
        setAddsLoadig(false);
      });
  };

  const preSelectedOptions = dataList.filter((option) =>
    selectedValues?.includes(option.id)
  );

  console.log(preSelectedOptions, "preSelectedOptions");

  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onHide={props.onHide}>
        <Modal.Title id="contained-modal-title-vcenter">
          <Container>
            <div> View Profile</div>
          </Container>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Form onSubmit={handleSubmit}>
            <Col xs={12} md={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    height: "90px",
                    width: "90px",
                    borderRadius: "50%",
                  }}
                  src={props?.data?.image_path || avatar3}
                  name="profile"
                />
              </div>
            </Col>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    className="linearBorder"
                    size="lg"
                    type="text"
                    placeholder="Enter School Name"
                    name="school_name"
                    autoFocus
                    disabled
                    defaultValue={props?.data?.first_name}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    className="linearBorder"
                    size="lg"
                    type="text"
                    placeholder="Enter School Name"
                    name="school_name"
                    autoFocus
                    disabled
                    defaultValue={props?.data?.email}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>School Name or Educational institute</Form.Label>
                  <Form.Control
                    className="linearBorder"
                    size="lg"
                    type="text"
                    placeholder="Enter School Name"
                    name="school_name"
                    autoFocus
                    disabled
                    defaultValue={props?.data?.school_name}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Grade Level</Form.Label>
                  <Form.Control
                    className="linearBorder"
                    size="lg"
                    type="text"
                    placeholder="Enter School Name"
                    name="school_name"
                    autoFocus
                    disabled
                    defaultValue={props?.data?.grade_level}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group>
              <Form.Label>About</Form.Label>
              <Form.Control
                className="linearBorder"
                as="textarea"
                rows={3}
                placeholder="Enter About Your Self"
                name="about"
                value={postDescription}
                disabled
                onChange={handleInputChange}
                style={{ position: "relative", width: "100%", height: "200px" }}
              />
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ViewProfile;
