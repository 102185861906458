import React from "react";

const Loader = () => {
    return (
        <>
            <div className=" container d-flex justify-content-center align-items-center w-full" style={{ height: '70vh' }}>
                <div className="spinner-grow" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </>
    );
};

export default Loader;