import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Image, Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../assets/logo.svg";
import home from "../../assets/Vector.svg";
import profile from "../../assets/profile.svg";
import "./style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import skillsModel from "models/skills.model";
import { helper } from "lib/helper";
import { Modal, Form } from "react-bootstrap";
import avatar3 from "../../assets/profile.svg";
import {
  Input,
  InputGroup,
  InputGroupText,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import "bootstrap/dist/css/bootstrap.min.css";

function Navs() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isProfileRoute = location.pathname === "/profile";
  const isMessagesRoute = location.pathname === "/messages";
  const isAchievments = location.pathname === "/acheivements";
  const isSuggestion = location.pathname === "/suggestion";
  const shouldShowNavbar =
    isProfileRoute || isMessagesRoute || isAchievments || isSuggestion;

  const [userData, setUserData] = useState("");

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userId");
    if (userDataString) {
      setUserData(userDataString);
    }
  }, [sessionStorage.getItem("userId"), location, userData]); // Empty dependency array to run this effect only once when the component mounts

  const navigate = useNavigate();

  // Parse userData only if it's not empty
  const [dataList, setDataList] = useState([]);
  const [search, setSearch] = useState("");
  const getListData = async () => {
    const data = {
      email: sessionStorage.userEmail,
      pageNo: 1,
      limit: 1000000000000,
      search: search,
    };
    await skillsModel
      .AllUser(data)
      .then((result) => {
        if (result) {
          setDataList(result.data);
        }
      })
      .catch((error) => {
        // helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  useEffect(() => {
    getListData();
  }, [location, search]);

  console.log(dataList, "dataListinput");

  const [filteredUsers, setFilteredUsers] = useState([]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);

    if (value) {
      const filtered = dataList.filter((item) =>
        item.first_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers([]);
    }
  };
  console.log(dataList, "filteredUsers");
  return shouldShowNavbar ? null : (
    <Navbar
      // style={{boxShadow: `0 0 6px 3px rgba(0, 0, 0, 0.3)`}}
      collapseOnSelect
      expand="lg"
      className="bg-body-white"
    >
      <Container>
        <Navbar.Brand>
          <Container>
            <Row style={{ display: "flex", flexDirection: "row" }}>
              <Col xs={6} md={4} href="/">
                <Image src={logo} rounded />
              </Col>
              <Col xs={6} md={2}>
                {userData && (
                  <div className="search-container">
                    <div className="input-group">
                      <Input
                        type="text"
                        className="search-input"
                        placeholder="Search..."
                        value={search}
                        onChange={handleSearchChange}
                        style={{
                          border: "1px solid #ededed",
                          outline: "none important",
                        }}
                      />
                      <span className="search-icon-container">
                        <i className="fas fa-search search-icon" />
                      </span>
                    </div>
                    {search && filteredUsers.length > 0 && (
                      <ListGroup className="search-results">
                        {filteredUsers.map((item) => (
                          <ListGroupItem
                            key={item.id}
                            onClick={() => {
                              setSearch(item.first_name);
                              handleShow();
                            }}
                          >
                            {item.first_name}
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <div
              className={`d-flex justify-content-between gap-4 flex-wrap ${
                windowWidth <= 991 ? "flex-column mt-3" : "flex-row"
              } navbarLinks`}
            >
              <div
                onClick={() => navigate("/")}
                className={`d-flex  ${
                  windowWidth <= 991
                    ? "justify-content-start gap-2"
                    : "justify-content-between gap-2"
                }  align-items-center`}
                style={{ cursor: "pointer" }}
              >
                <div>
                  <img style={{ height: "23px", width: "23px" }} src={home} />
                </div>
                <Link className="navbar-links" to="/">
                  Home
                </Link>
              </div>

              {userData && (
                <div
                  onClick={() => navigate("/profile")}
                  className={`d-flex  ${
                    windowWidth <= 991
                      ? "justify-content-start gap-2"
                      : "justify-content-between gap-2"
                  }  align-items-center`}
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <img
                      style={{ height: "23px", width: "23px" }}
                      src={profile}
                    />
                  </div>
                  <Link className="navbar-links" eventKey={2} to="/profile">
                    Profile
                  </Link>
                </div>
              )}

              {!userData && (
                <div
                  className={`d-flex  ${
                    windowWidth <= 991
                      ? "justify-content-start gap-2"
                      : "justify-content-between gap-2"
                  }  align-items-center`}
                >
                  {/* <div>
      <img style={{ height: '23px', width: '23px' }} src={profile} />
    </div> */}
                  <Link
                    className="navbar-links"
                    eventKey={2}
                    to="/register/login"
                  >
                    <Button tag={Link} to="/register/login" className="likeBtn">
                      Login
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <Container>
              <div> </div>
              {dataList && dataList?.map((item)=>{
                return(
 <div>
{item?.first_name}
 </div>
              )})}
             
            </Container>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form>
              {dataList &&
                dataList?.map((item, index) => {
                  return (
                    <div>
                      <Col xs={6} md={12}>
                        <div className="text-center">
                          <label htmlFor="fileInput" className="image-input">
                            <div className="rounded-gradient-borders">
                              {item.image_path ? (
                                <img
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "100%",
                                  }}
                                  src={item.image_path}
                                  // alt="Selected Profile"
                                  name="profile"
                                />
                              ) : (
                                <img
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    paddingTop: "20px",
                                  }}
                                  src={avatar3}
                                  alt="Default Profile"
                                  name="profile"
                                />
                              )}
                            </div>
                            
                          </label>
                        </div>
                      </Col>
                      <div className="user-info-card">
      <div className="info-item">
        <label className="lable"><i className="fas fa-user "></i> Name:</label>
        <span className="span">{item?.first_name}</span>
      </div>

      <div className="info-item">
        <label className="lable"><i className="fas fa-envelope "></i> Email:</label>
        <span>{item?.email}</span>
      </div>

      <div className="info-item">
        <label className="lable"><i className="fas fa-school "></i> School Name:</label>
        <span className="span">{item?.school_name}</span>
      </div>

      <div className="info-item">
        <label className="lable"><i className="fas fa-graduation-cap "></i> Grade Level:</label>
        <span className="span">{item?.grade_level}</span>
      </div>

      <div className="info-item">
        <label className="lable"><i className="fas fa-info-circle "></i> About:</label>
        <span className="span">{item?.user_bio}</span>
      </div>
    </div>
                    </div>
                  );
                })}
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </Navbar>
  );
}

export default Navs;
