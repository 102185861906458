import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import logo from "../../assets/logo.svg";
import { MdOutlinePersonOutline } from "react-icons/md";
import { FiHome } from "react-icons/fi";
import { FiZap } from "react-icons/fi";
import { MdLogout } from "react-icons/md";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Logout from "../Logout";
import { SiGitconnected } from "react-icons/si";
import Swal from "sweetalert2";
import "./style.css";

const Sidebar = ({ children }) => {
  const location = useLocation();
  const [isLogout, setIsLogout] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    window.innerWidth <= 768 ? false : true
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    if (window.innerWidth > 768) {
      setIsSidebarOpen(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const params = useParams();

  const navigate = useNavigate();
  const handleLogout = async () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      // icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Logout",
      allowOutsideClick: false,
      allowEscapeKey: false,
      reverseButtons: true,
      focusCancel: true,
      customClass: {
        confirmButton: "confirm-button-class",
        title: "title-class",
        icon: "icon-class",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("usertocken");
        sessionStorage.removeItem("userEmail");
        sessionStorage.removeItem("userrole");
        sessionStorage.removeItem("userId");
        sessionStorage.removeItem("usertoken");
        sessionStorage.removeItem("userinfo");
        navigate("/register/login");
        window.location.reload();
      }
    });
  };

  return (
    <div className={`container-fluid`}>
      {windowWidth <= 768 && (
        <span className={styles.toggleButton} onClick={toggleSidebar}>
          <i className="fa-solid fa-bars"></i>
        </span>
      )}
      <div className="row flex-nowrap"  >
        <div
          className={`col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-white ${
            styles.sidebarDiv
          } ${isSidebarOpen || windowWidth > 768 ? "" : "d-none"}`}
        >
          <hr
            style={
              windowWidth <= 576
                ? { position: "relative", top: "90px", left: "0px" ,}
                : { position: "relative", top: "90px", left: "0px", }
            }
          />
          <div className={`px-3 pt-2 text-white min-vh-100 `} style={{borderRight:"1px solid #ebe8e8",}} >
            <a
              href="/"
              className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
            >
              <span className={`fs-5 d-sm-inline`}>
                <img
                  className={windowWidth <= 576 ? "" : ""}
                  src={logo}
                  alt="Logo"
                />
              </span>
            </a>
            <ul
              className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
              id="menu"
            >
              <li className="nav-item mt-3 p-0">
                <Link
                  to="/profile"
                  className={`d-flex align-items-center ${styles.sidelinks}`}
                >
                  <MdOutlinePersonOutline
                    style={{ fontSize: "20px" }}
                    className={`${
                      location.pathname === "/profile"
                        ? `${styles.sidebaactivetabicon}`
                        : `${styles.sidebadeactivetabicon}`
                    }`}
                  />
                  <span
                    className={`${
                      location.pathname === "/profile"
                        ? `${styles.sidebaactivetabname}`
                        : `${styles.sidebadeactivetabname}`
                    }`}
                  >
                    View Profile
                  </span>
                </Link>
              </li>

              <li className="mt-2">
                <Link
                  to="/acheivements"
                  className={`d-flex align-items-center ${styles.sidelinks}`}
                >
                  <FiHome
                    style={{ fontSize: "20px" }}
                    className={`${
                      location.pathname === "/acheivements"
                        ? `${styles.sidebaactivetabicon}`
                        : `${styles.sidebadeactivetabicon}`
                    }`}
                  />
                  <span
                    className={`${
                      location.pathname === "/acheivements"
                        ? `${styles.sidebaactivetabname}`
                        : `${styles.sidebadeactivetabname}`
                    }`}
                  >
                    Update
                  </span>
                </Link>
              </li>

              <li className="mt-2">
                <Link
                  to="/messages"
                  className={`d-flex align-items-center ${styles.sidelinks}`}
                >
                  <FiZap
                    style={{ fontSize: "20px" }}
                    className={`${
                      location.pathname === "/messages"
                        ? `${styles.sidebaactivetabicon}`
                        : `${styles.sidebadeactivetabicon}`
                    }`}
                  />
                  <span
                    className={`${
                      location.pathname === "/messages"
                        ? `${styles.sidebaactivetabname}`
                        : `${styles.sidebadeactivetabname}`
                    }`}
                  >
                    Messages
                  </span>
                </Link>
              </li>

              <li className="mt-2">
                <Link
                  to="/suggestion"
                  className={`d-flex align-items-center ${styles.sidelinks}`}
                >
                  <SiGitconnected
                    style={{ fontSize: "20px" }}
                    className={`${
                      location.pathname === "/suggestion"
                        ? `${styles.sidebaactivetabicon}`
                        : `${styles.sidebadeactivetabicon}`
                    }`}
                  />
                  <span
                    className={`${
                      location.pathname === "/suggestion"
                        ? `${styles.sidebaactivetabname}`
                        : `${styles.sidebadeactivetabname}`
                    }`}
                  >
                    Suggestion
                  </span>
                </Link>
              </li>

              <li className="mt-2">
                <div
                  onClick={handleLogout}
                  className={`d-flex align-items-center ${styles.sidelinks}`}
                >
                  <MdLogout
                    style={{ fontSize: "20px", marginTop: "5px" }}
                    className={`${styles.sidebadeactivetabicon}`}
                  />
                  <span
                    style={{ cursor: "pointer" }}
                    className={`${styles.sidebadeactivetabname}`}
                  >
                    Logout
                  </span>
                </div>
              </li>
            </ul>

            <hr />
          </div>
        </div>

        <div
          className={`${
            location.pathname === "/acheivements"
              ? `${styles.componentrender} col py-3 mb-5`
              : "col py-3 mb-5"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;




