import React, { useState } from 'react';
import styles from './style.module.css';
import { Badge, Button, Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import avatar1 from '../../assets/Avatar1.svg';
import avatar2 from '../../assets/Avatar2.svg';
import avatar3 from '../../assets/Avatar3.svg';

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(-1); // State to keep track of the currently open question index

    // Function to toggle the open/close state of a question
    const toggleQuestion = (index) => {
        setOpenIndex(openIndex === index ? -1 : index);
    };

    // Sample FAQ items data
    const faqItems = [
      
        {
            question: 'What is Skill Linkers?',
            answer: 'Skill Linkers is a skill exchange platform that creates a network of students based on their skills and interests, allowing them to share, learn, and collaborate on various projects or activities.',
        },

        {
            question: 'How do I communicate with other users?',
            answer: 'You can communicate with other users through the messaging feature, which allows you to discuss projects, set goals, and plan skill exchanges.',
        },

        {
            question: 'How can I showcase my skills and achievements on my profile?',
            answer: 'You can showcase your skills, projects, certifications, and achievements on your profile by adding descriptions, portfolios, and links to relevant work.',
        },
        // Add more FAQ items as needed
    ];

    return (
        <div>
            <Container>
                <div className='text-center'>
                    <div className='gradiant-text text-center gradiantHeadings'>Frequently asked questions</div>
                    <p className='subText'>Everything you need to know about the product and billing.</p>
                </div>
                <Container>
                    <Row className="justify-content-md-center mt-5">
                        <Col xs lg={7}>
                            <div className='text-justify'>
                                <ListGroup className='mb-2' variant="flush" >
                                    {/* Mapping through the FAQ items */}
                                    {faqItems.map((item, index) => (
                                        <ListGroup.Item
                                            key={index}
                                            className="d-flex justify-content-between align-items-start mt-2"
                                            onClick={() => toggleQuestion(index)} // Toggle the question when clicked
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div className="ms-2 me-auto mb-3">
                                                <div className={`fw-bold ${styles.listHeadings} fontWeight500`}>{item.question}</div>
                                                {openIndex === index && // Display the answer if the question is open
                                                    <div>{item.answer}</div>
                                                }
                                            </div>
                                            {/* Displaying different icons based on whether the question is open or closed */}
                                            <span>

                                            {openIndex === index ? <FiMinusCircle color='#01A8CC' size={20} /> : <FiPlusCircle color='#01A8CC' size={20} />}
                                            </span>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <div className='mb-5'>
                    {/* Your "Get in Touch" card */}
                </div>
            </Container>
        </div>
    )
}

export default FAQ;
