// import React, { useEffect, useState } from "react";
// import Sidebar from "../../components/Sidebar";
// // import styles from './style.module.css';
// import styles from "./style.module.css";
// import logo from "../../assets/logo.svg";
// import {
//   Button,
//   Card,
//   Col,
//   Container,
//   Form,
//   Image,
//   InputGroup,
//   ListGroup,
//   Modal,
//   Navbar,
//   Row,
//   Spinner,
// } from "react-bootstrap";
// import avatar3 from "../../assets/Avatar.jpeg";
// import { MdOutlinePersonOutline } from "react-icons/md";
// import { FiHome } from "react-icons/fi";
// import { FiZap } from "react-icons/fi";
// import { MdLogout } from "react-icons/md";
// import { FiMinusCircle } from "react-icons/fi";
// import { MdEdit } from "react-icons/md";
// import { FaPlus } from "react-icons/fa6";
// import { SiPluscodes } from "react-icons/si";
// import { IoMdSend } from "react-icons/io";
// import shutterstock from "../../assets/shutterstock.svg";
// import SkillsModel from "models/skills.model";
// import { helper } from "lib/helper";
// import AddNewPost from "pages/Profile/AddNewPost";
// import skillsModel from "models/skills.model";
// import media from "../../assets/media.svg";

// const Acheivements = () => {
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
//   const [show, setShow] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const handleResize = () => {
//     setWindowWidth(window.innerWidth);
//   };
//   const [addpost, setAddPost] = useState(false);

//   useEffect(() => {
//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const [allpost, setAllPost] = useState([]);
//   const getListDataAllPOst = async () => {
//     setIsLoading(true);
//     const data = {
//       email: sessionStorage.getItem("userEmail"),
//       pageNo: 1,
//       limit: 1000000000,
//     };
//     await SkillsModel.AllPost(data)

//       .then((result) => {
//         if (result) {
//           setIsLoading(false);
//           console.log(result, "result");
//           setAllPost(result.data);
//         }
//       })
//       .catch((error) => {
//         setIsLoading(false);
//         // helper.sweetalert.toast(error.response?.data?.message, "warning");
//       });
//   };

//   useEffect(() => {
//     getListDataAllPOst();
//   }, []);

//   console.log(allpost, "allpost");

//   const [addsLoadig, setAddsLoadig] = useState(false);

//   const [postTitle, setPostTitle] = useState("");
//   const [postDescription, setPostDescription] = useState("");
//   const [selectedImage, setSelectedImage] = useState("");

//   const clearData = () => {
//     setPostTitle("");
//     setPostDescription("");
//     setSelectedImage("");
//   };
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "post_name") {
//       setPostTitle(value);
//     } else if (name === "about") {
//       setPostDescription(value);
//     }
//   };

//   // Function to handle image selection
//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const imageUrl = URL.createObjectURL(file);
//       setSelectedImage(imageUrl);
//     }
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     setAddsLoadig(true);
//     const form = new FormData(event.target);
//     form.append("email", sessionStorage.getItem("userEmail"));

//     skillsModel
//       .adduserPostNew(form)
//       .then((response) => {
//         getListDataAllPOst();
//         clearData();
//         handleClose();
//         if (response.data.status == "success") {
//           helper.sweetalert.toast(response.data.msg, "success");
//         } else {
//           helper.sweetalert.toast(response.data.msg, "error");
//         }
//       })
//       .catch((error) => {
//         // Handle error

//         console.log(error, "asdnn");
//       })
//       .finally(() => {
//         setAddsLoadig(false);
//       });
//   };

//   const [likedStates, setLikedStates] = useState(
//     new Array(allpost.length).fill(false)
//   );

//   const handleSubmitLikeToggle = (index) => {
//     const data = {
//       email: sessionStorage.getItem("userEmail"),
//       postId: allpost[index].id,
//     };

//     const isLiked = likedStates[index]; // Current like status of the post

//     skillsModel
//       .addPostLike(data)
//       .then((response) => {
//         console.log(response, "likepost");
//         // Toggle the liked state for the corresponding post
//         // const updatedLikedStates = [...likedStates];
//         // updatedLikedStates[index] = !isLiked;
//         // setLikedStates(updatedLikedStates);

//         // Refresh post data
//         getListDataAllPOst();
//         helper.sweetalert.toast(response.data.msg, "success");
//         setAddsLoadig(false);
//       })
//       .catch((error) => {
//         setAddsLoadig(false);
//         // console.log(error);
//       });
//   };

//   const userId = sessionStorage.getItem("userId");

//   return (
//     <div>
//       <Sidebar>
//         <Container fluid>
//           <Row>
//             {/* Left Sidebar - Users */}
//             {/* Right Sidebar - Chats and Input */}
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 // justifyContent: "space-between",
//                 boxShadow: `0 2px 3px -1px rgba(0, 0, 0, 0.1)`,
//               }}
//             >
//               <div style={{ width: "100%" }}>
//                 {" "}
//                 <span style={{ fontSize: "16px", fontWeight: "bold" }}>
//                   Update
//                 </span>
//               </div>

//               <div>
//                 <Button
//                   onClick={handleShow}
//                   size="sm"
//                   className={`${styles.likeBtn}`}
//                   style={{ width: "100px", marginBottom: "4px" }}
//                 >
//                   <FaPlus
//                     color="white"
//                     style={{ marginBottom: "4px", marginRight: "3px" }}
//                   />
//                   <span>New Post</span>
//                 </Button>
//               </div>
//             </div>

//             <Col md={12} className={`${styles.rightSidebar}`}>
//               {/* Chat Messages */}
//               <div className={`${styles.chatMessages} mt-3`}>
//                 <Container>
//                   <Row>
//                     <Col>
//                       {isLoading ? (
//                         <div
//                           style={{
//                             display: "flex",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             color: "#01A8CC",
//                           }}
//                         >
//                           <Spinner></Spinner>
//                         </div>
//                       ) : allpost?.length > 0 ? (
//                         allpost?.map((item, index) => {
//                           return (
//                             <div>
//                               <Card
//                                 style={{
//                                   backgroundColor: "#F5F5F5",
//                                   border: "none",
//                                   boxShadow: "1px 2px 5px -3px gray",
//                                 }}
//                               >
//                                 {/* <Card.Header>Header</Card.Header> */}
//                                 <Card.Body>
//                                   <div className="d-flex align-items-center gap-2">
//                                     <div>
//                                       <img
//                                         alt=""
//                                         src={item.image_path || avatar3}
//                                         width="40"
//                                         height="40"
//                                         className="d-inline-block align-top"
//                                         style={{ borderRadius: "50%" }}
//                                       />{" "}
//                                     </div>
//                                     <div
//                                       style={{
//                                         fontSize: "20px",
//                                         fontWeight: 400,
//                                       }}
//                                     >
//                                       {" "}
//                                       {item.first_name}
//                                       <p
//                                         style={{
//                                           fontSize: "12px",
//                                           fontWeight: 400,
//                                         }}
//                                       >
//                                         Posted {item.created_at}
//                                       </p>
//                                     </div>
//                                     {/* <p style={{fontSize:'16px', fontWeight:400}}>Online</p> */}
//                                   </div>
//                                   {item.media ===
//                                   "https://sklinker.ablive.in/" ? (
//                                     <span></span>
//                                   ) : (
//                                     <Card.Img
//                                       variant="top"
//                                       src={item.media}
//                                       style={{
//                                         maxHeight: "70vh", // Limit the maximum height of the image
//                                         objectFit: "contain", // Maintain aspect ratio while fitting inside the container
//                                         width: "100%", // Ensure the image fills the container horizontally
//                                       }}
//                                     />
//                                   )}

//                                   <Card.Title className="mt-2">
//                                     {item.post_name}
//                                   </Card.Title>
//                                   <Card.Text>{item.about}</Card.Text>
//                                 </Card.Body>
//                                 <Card.Footer
//                                   style={{
//                                     backgroundColor: "white",
//                                     border: "1px solid white",
//                                   }}
//                                 >
//                                   <div className="d-flex justify-content-between">
//                                     <div className="primaryColor fw-bold">
//                                       {item.like_count} Likes
//                                     </div>
//                                     {/* <Button
//                                       className={`${styles.likeBtn}`}
//                                       size="sm"
//                                     >
//                                       Like
//                                     </Button> */}

//                                     <Button
//                                       className={`${
//                                         likedStates[index]
//                                           ? styles.unlikeBtn
//                                           : styles.likeBtn
//                                       }`}
//                                       size="sm"
//                                       onClick={() =>
//                                         handleSubmitLikeToggle(index)
//                                       }
//                                     >
//                                       {item.liked_user_id &&
//                                       item.liked_user_id.includes(userId)
//                                         ? "Unlike"
//                                         : "Like"}
//                                     </Button>
//                                   </div>
//                                 </Card.Footer>
//                               </Card>
//                               <br />
//                             </div>
//                           );
//                         })
//                       ) : (
//                         <div
//                           style={{
//                             display: "flex",
//                             justifyContent: "center",
//                             alignItems: "center",
//                           }}
//                         >
//                           <span>No updates found</span>
//                         </div>
//                       )}
//                     </Col>
//                   </Row>
//                 </Container>
//               </div>
//               {/* Chat Input */}
//             </Col>
//           </Row>
//         </Container>
//       </Sidebar>

//       {/* <AddNewPost show={addpost} onHide={() => setAddPost(false)} /> */}

//       <Modal
//         show={show}
//         onHide={handleClose}
//         size="md"
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         <Modal.Header closeButton onHide={handleClose}>
//           <Modal.Title id="contained-modal-title-vcenter">New Post</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={handleSubmit}>
//             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//               <Form.Label className={styles.postInputLabel}>
//                 Post Title
//               </Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Post Title"
//                 name="post_name"
//                 autoFocus
//                 value={postTitle}
//                 onChange={handleInputChange}
//               />
//             </Form.Group>
//             <Form.Group
//               className="mb-3"
//               controlId="exampleForm.ControlTextarea1"
//             >
//               <Form.Label className={styles.postInputLabel}>
//                 Post Description
//               </Form.Label>
//               <Form.Control
//                 as="textarea"
//                 rows={3}
//                 placeholder="Enter Post Description"
//                 name="about"
//                 value={postDescription}
//                 onChange={handleInputChange}
//               />
//             </Form.Group>
//             <div className="d-grid gap-2 ">
//               <div className="d-flex justify-content-between">
//                 <div className="mt-2">
//                   <label htmlFor="mediaInput">
//                     <img
//                       src={selectedImage || media}
//                       alt="Selected Image"
//                       style={{ height: "20px", width: "20px" }}
//                     />
//                     <span style={{ marginLeft: "20px" }}>Add Media</span>
//                   </label>
//                   <input
//                     id="mediaInput"
//                     type="file"
//                     accept="image/*"
//                     onChange={handleImageChange}
//                     style={{ display: "none" }}
//                     name="media"
//                   />
//                 </div>
//                 <div>
//                   <Button
//                     className={`linearBg ${styles.postBtn}`}
//                     variant="secondary"
//                     size="lg"
//                     type="submit"
//                   >
//                     Post Now
//                   </Button>
//                 </div>
//               </div>
//             </div>
//           </Form>
//         </Modal.Body>
//       </Modal>
//     </div>
//   );
// };

// export default Acheivements;

import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import styles from "./style.module.css";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  ListGroup,
  Modal,
  Navbar,
  Row,
  Spinner,
} from "react-bootstrap";
import avatar3 from "../../assets/Avatar.jpeg";
import { FaPlus } from "react-icons/fa6";
import media from "../../assets/media.svg";
import SkillsModel from "models/skills.model";
import { helper } from "lib/helper";
import skillsModel from "models/skills.model";

const Acheivements = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allpost, setAllPost] = useState([]);
  const [addsLoadig, setAddsLoadig] = useState(false);
  const [postTitle, setPostTitle] = useState("");
  const [postDescription, setPostDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getListDataAllPOst = async () => {
    setIsLoading(true);
    const data = {
      email: sessionStorage.getItem("userEmail"),
      pageNo: 1,
      limit: 1000000000,
    };
    await SkillsModel.AllPost(data)
      .then((result) => {
        if (result) {
          setIsLoading(false);
          setAllPost(result.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getListDataAllPOst();
  }, []);

  const clearData = () => {
    setPostTitle("");
    setPostDescription("");
    setSelectedImage("");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "post_name") {
      setPostTitle(value);
    } else if (name === "about") {
      setPostDescription(value);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setAddsLoadig(true);
    const form = new FormData(event.target);
    form.append("email", sessionStorage.getItem("userEmail"));
    skillsModel
      .adduserPostNew(form)
      .then((response) => {
        getListDataAllPOst();
        clearData();
        handleClose();
        if (response.data.status === "success") {
          helper.sweetalert.toast(response.data.msg, "success");
        } else {
          helper.sweetalert.toast(response.data.msg, "error");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setAddsLoadig(false);
      });
  };

  const handleSubmitLikeToggle = (index) => {
    const data = {
      email: sessionStorage.getItem("userEmail"),
      postId: allpost[index].id,
    };

    skillsModel
      .addPostLike(data)
      .then((response) => {
        console.log(response, "likepost");
        getListDataAllPOst();
        helper.sweetalert.toast(response.data.msg, "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const userId = sessionStorage.getItem("userId");

  return (
    <div>
      <Sidebar>
        <Container fluid>
          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center", // Align items vertically in the center
                justifyContent: "space-between", // Space out items evenly
                boxShadow: "0 2px 3px -1px rgba(0, 0, 0, 0.1)",
                padding: "10px", // Optional: Add padding for better spacing
              }}
            >
              <div>
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Update
                </span>
              </div>
              <div>
                <Button
                  onClick={handleShow}
                  size="sm"
                  className={`${styles.likeBtn}`}
                  style={{
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FaPlus color="white" style={{ marginRight: "3px" }} />
                  <span>New Post</span>
                </Button>
              </div>
            </div>

            <Col md={12} className={`${styles.rightSidebar}`}>
              <div className={`${styles.chatMessages} mt-3`}>
                <Container>
                  <Row>
                    <Col>
                      {isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#01A8CC",
                          }}
                        >
                          <Spinner></Spinner>
                        </div>
                      ) : allpost?.length > 0 ? (
                        allpost?.map((item, index) => (
                          <div key={item.id}>
                            <Card
                              style={{
                                backgroundColor: "#F5F5F5",
                                border: "none",
                                boxShadow: "1px 2px 5px -3px gray",
                              }}
                            >
                              <Card.Body>
                                <div className="d-flex align-items-center gap-2">
                                  <div>
                                    {/* {
                                      item?.image_path === ""
                                    } */}
                                    <img
                                      alt=""
                                      src={item.image_path || avatar3}
                                      width="40"
                                      height="40"
                                      className="d-inline-block align-top"
                                      style={{ borderRadius: "50%" }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {item.first_name}
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: 400,
                                      }}
                                    >
                                      Posted {item.created_at}
                                    </p>
                                  </div>
                                </div>
                                {item.media !== "https://skilllinkers.com/" && (
                                  <Card.Img
                                    variant="top"
                                    src={item.media}
                                    style={{
                                      maxHeight: "70vh",
                                      objectFit: "contain",
                                      width: "100%",
                                    }}
                                  />
                                )}
                                <Card.Title className="mt-2">
                                  {item.post_name}
                                </Card.Title>
                                <Card.Text>{item.about}</Card.Text>
                              </Card.Body>
                              <Card.Footer
                                style={{
                                  backgroundColor: "white",
                                  border: "1px solid white",
                                }}
                              >
                                <div className="d-flex justify-content-between">
                                  <div className="primaryColor fw-bold">
                                    {item.like_count} Likes
                                  </div>
                                  <Button
                                    className={`${
                                      item.liked_user_id.find(
                                        (id) => id == userId
                                      ) !== undefined
                                        ? styles.unlikeBtn
                                        : styles.likeBtn
                                    }`}
                                    size="sm"
                                    onClick={() =>
                                      handleSubmitLikeToggle(index)
                                    }
                                  >
                                    {item.liked_user_id.find(
                                      (id) => id == userId
                                    ) !== undefined
                                      ? "Unlike"
                                      : "Like"}
                                  </Button>
                                </div>
                              </Card.Footer>
                            </Card>
                            <br />
                          </div>
                        ))
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>No updates found</span>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </Sidebar>

      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onHide={handleClose}>
          <Modal.Title id="contained-modal-title-vcenter">New Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className={styles.postInputLabel}>
                Post Title
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Post Title"
                name="post_name"
                autoFocus
                value={postTitle}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className={styles.postInputLabel}>
                Post Description
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Post Description"
                name="about"
                value={postDescription}
                onChange={handleInputChange}
              />
            </Form.Group>
            <div className="d-grid gap-2">
              <div className="d-flex justify-content-between">
                <div className="mt-2">
                  <label htmlFor="mediaInput">
                    <img
                      src={selectedImage || media}
                      alt="Selected Image"
                      style={{ height: "20px", width: "20px" }}
                    />
                    <span style={{ marginLeft: "20px" }}>Add Media</span>
                  </label>
                  <input
                    id="mediaInput"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    name="media"
                  />
                </div>
                <div>
                  <Button
                    className={`linearBg ${styles.postBtn}`}
                    variant="secondary"
                    size="lg"
                    type="submit"
                  >
                    Post Now
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Acheivements;
