import React, { useEffect } from "react";
import "./style.module.css";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  ListGroup,
  Row,
} from "react-bootstrap";
import { FiPlusCircle } from "react-icons/fi";
import person from "../../assets/person.svg";
import homepagenew from '../../assets/homepagenew.jpeg'

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Yahaan par scroll ko top par laane ke liye window.scrollTo ka istemal kiya gaya hai
  }, []);
  return (
    <div>
      <Container>
        <div className="text-center">
          <div className="gradiant-text text-center gradiantHeadings">
            About Skill Linkers
          </div>
          {/* <p className="subText">
            Everything you need to know about the skills.
          </p> */}
        </div>
        <div>
          <Container>
            <div className="mt-5 mb-5">
              <Row xs={1} md={2} className="g-4 border-none">
                <Col>
                  <Card className="mt-5 border-none">
                    <Card.Body>
                      <Card.Text  style={{ textAlign: "justify",}}>
                        <p className=" home-text2">
                          <span className="home-text">
                            Skill Exchange Platform :{" "}
                          </span>
                          Our platform serves as a dynamic hub for highschoolers
                           to exchange skills seamlessly. Whether you're
                          honing your coding abilities or perfecting your public
                          speaking, our system pairs you with peers eager to
                          share their expertise.{" "}
                        </p>
                        <p className="mt-4 home-text2  home">
                          <span className="home-text">
                            {" "}
                            Randomized Pairing Algorithm :{" "}
                          </span>
                          Through our innovative randomized pairing algorithm,
                          each interaction brings fresh learning opportunities.
                          This approach fosters diverse learning experiences,
                          empowering you to develop a well-rounded skill set.
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card className="border-none">
                    <Card.Body>
                      <Card.Text>
                        <img
                          className="img-thumbnail border-none"
                          src={homepagenew}
                          alt="..."
                          style={{marginTop:"50px",borderRadius:"10px"}}
                        />
                        {/* <img src="..." alt="..." class="img-thumbnail"></img> */}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <div style={{ textAlign: "justify", marginLeft: "15px" }}>
                <p className="mt-4 home-text2">
                  <span className="home-text"> Community-Centric Focus : </span>
                  Beyond skill development, we prioritize community building.
                  Our platform cultivates a supportive environment where
                  meaningful connections flourish, enriching your learning
                  journey.
                </p>

                <p className="mt-4 home-text2">
                  <span className="home-text"> Intuitive Interface : </span>
                  Designed for ease of use, our user-friendly interface
                  simplifies profile creation, skill exchange, and connection
                  with your learning partner. Our streamlined processes ensure a
                  seamless experience.
                </p>
                <p className="mt-4 home-text2">
                  <span className="home-text"> Mission Statement : </span>
                  Our mission is to empower highschoolers by facilitating
                  skill enhancement and fostering a network of collaborative
                  learning. Join us in creating a community where every skill
                  shared propels personal and collective growth.
                </p>
                <p className="mt-4 home-text2">
                  Embrace the opportunity to connect, learn, and grow with us.
                  Together, let's pave the way for a brighter future through
                  skill exchange and community collaboration.
                </p>
              </div>
            </div>
          </Container>
        </div>
      </Container>
    </div>
  );
};

export default AboutPage;
