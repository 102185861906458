


import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import styles from "./style.module.css";
import media from "../../assets/media.svg";
import skillsModel from "models/skills.model";
import { helper } from "lib/helper";

function AddNewPost(props) {
  const [addsLoadig, setAddsLoadig] = useState(false);

  const [postTitle, setPostTitle] = useState('');
    const [postDescription, setPostDescription] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
  
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'post_name') {
            setPostTitle(value);
        } else if (name === 'about') {
            setPostDescription(value);
        }
    };



  // Function to handle image selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };


  const handleSubmit = (event) => {
    setAddsLoadig(true);
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("email", sessionStorage.getItem("userEmail"));
    // You can adjust the header configuration according to your API requirements
    skillsModel
      .adduserPostNew(form)
      .then((response) => {
        setSelectedImage("");
        props.onHide();
        setAddsLoadig(false);
        if (response.data.status == "success") {
          helper.sweetalert.toast(response.data.msg, "success");
        }
        else{

          helper.sweetalert.toast(response.data.msg, "error");
        }
      
      })
      .catch((error) => {
        setAddsLoadig(false);
        // console.log(error);
      });
  };
  return (
    <>
      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>

      <Modal.Header closeButton  {...props}>

            <Modal.Title  id="contained-modal-title-vcenter">
                New Post
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className={styles.postInputLabel}>
                Post Title
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Post Title"
                name="post_name"
                autoFocus
                value={postTitle}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className={styles.postInputLabel}>
                Post Description
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Post Description"
                name="about"
                value={postDescription}
                onChange={handleInputChange}
              />
            </Form.Group>
            <div className="d-grid gap-2">
              <div className="d-flex justify-content-between">
                <div className="mt-2">
                  <label htmlFor="mediaInput">
                    <img
                      src={selectedImage || media}
                      alt="Selected Image"
                      style={{ height: "20px", width: "20px" }}
                    />
                    <span style={{marginLeft:"20px"}}>Add Media</span>
                  </label>
                  <input
                    id="mediaInput"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    name="media"
                  />
                </div>
                <div>
                  <Button
                    className={`linearBg ${styles.postBtn}`}
                    variant="secondary"
                    size="lg"
                    type="submit"
                  >
                    Post Now
                  </Button>
                </div>
              </div>
            </div>
          </Form>
            </Modal.Body>
        </Modal>
    </>
  );
}

export default AddNewPost;

