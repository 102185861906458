// routes.js

import React from 'react';
import { Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import SignUp from '../pages/Registration';
import FAQ from '../pages/FAQ';
import ListOfSkills from '../pages/ListOfSkills';
import AboutPage from '../pages/About';
import Footer from '../components/Footer';
import Profile from '../pages/Profile';
import Messages from '../pages/Messages';
import Achievements from '../pages/Acheivements';
import Suggestion from '../pages/Suggestion';
import TermsAndConditions from 'pages/Registration/TermsAndConditions';
// import NotFound from './components/NotFound';
import PrivateRoute from 'Productroutes';
import EmailPage from 'pages/Registration/ResetPassword/EmailPage';

const routes = [
  {
    path: '/',
    component: <><HomePage /><Footer /></>,
    exact: true,
    isFooter: true
  },
  {
    path: '/profile',
    component:<><PrivateRoute><Profile/><Footer/> </PrivateRoute></>,
    exact: true,
    isFooter: false
  },
  {
    path: '/register/:id',
    component: <SignUp />,
    exact: true,
    isFooter: false
  },
  {
    path: '/faq',
    component: <><FAQ /><Footer /></>,
    exact: true,
    isFooter: false
  },

  {
    path: '/emailpage',
    component: <><EmailPage /></>,
    exact: true,
    isFooter: false
  },

  {
    path: '/termsandconditions',
    component: <><TermsAndConditions /><Footer /></>,
    exact: true,
    isFooter: false
  },
  {
    path: '/skills',
    component: <><ListOfSkills /><Footer /></>,
    exact: true,
    isFooter: false
  },
  {
    path: '/about',
    component: <><AboutPage /><Footer /></>,
    exact: true,
    isFooter: false
  },
  {
    path: '/messages',
    component: <> <PrivateRoute><Messages/><Footer /> </PrivateRoute></>,
    exact: true,
    isFooter: false
  },
  {
    path: '/acheivements',
    component: <> <PrivateRoute><Achievements/><Footer /> </PrivateRoute></>,
    exact: true,
    isFooter: false
  },
  {
    path: '/suggestion',
    component: <><PrivateRoute><Suggestion/><Footer /></PrivateRoute></>,
    exact: true,
    isFooter: false
  },
  {
    path: '*',
    component: "NotFound"
  }
];

export default routes;
