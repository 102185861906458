import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  CardGroup,
  Card,
  Spinner,
} from "react-bootstrap";
import "../style.css";
import shutterstock from "../../../assets/shutterstock.svg";
import { useNavigate } from "react-router-dom";
import { helper } from "lib/helper";
import resetpasswordModal from "models/resetpassword.modal";

function EmailPage() {
  const navigate = useNavigate();

  const [addsLoadig, setAddsLoadig] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [otpSuccess, setOtpSuccess] = useState(false);

  const [vaildData,setValidData] = useState()
  const handleSendotp = (event) => {
    setAddsLoadig(true);
    event.preventDefault();
    const form = new FormData(event.target);

    const emailLogin = form.get("email");
    const Otp = form.get("otp");

    const data = {
      email: emailLogin,
    };

    const dataOptresve = {
      email: emailLogin,
      otp: Otp,
    };

    if (!emailLogin) {
      helper.sweetalert.toast("Email is Required", "error");
    } else {
      resetpasswordModal
        .SendOtp(data)
        .then((response) => {
          console.log(response.status, "status");
          if (response.status === "success") {
            setValidData(response)
            setOtpSuccess(true);
          }
          helper.sweetalert.toast(response.msg, "success");
          setAddsLoadig(false);
        })
        .catch((error) => {
          setAddsLoadig(false);
          console.error(error);
        });
    }

    // Proceed with login if no errors
  };

  const [otpValid, setOtpValid] = useState(false);


const [optData,setOtpData] = useState()
  const handleValidateOtp = (event) => {
    setAddsLoadig(true);
    event.preventDefault();
    const form = new FormData(event.target);

    const emailLogin = form.get("email");
    const Otp = form.get("otp");

    const data = {
      email: emailLogin,
      otp: Otp,
    };

    resetpasswordModal
      .ValidatedOpt(data)
      .then((response) => { 
        if (response.success === "success") {
          setOtpData(response?.key)
          setOtpValid(true)
          setOtpSuccess(false);
        }
        helper.sweetalert.toast(response.msg, "success");
        setAddsLoadig(false);
      })
      .catch((error) => {
        setAddsLoadig(false);
        console.error(error);
      });

    // Proceed with login if no errors
  };


  const handlePasswordChange= (event) => {
    setAddsLoadig(true);
    event.preventDefault();
    const form = new FormData(event.target);
    const emailLogin = form.get("email");
    const password = form.get("password");

    const data = {
      email: vaildData.userId,
      password: password,
      key: optData
    };

    resetpasswordModal
      .resetPassowrd(data)
      .then((response) => { 
        if (response.status === "success") {
          setOtpValid(false)
          setOtpSuccess(false);
           navigate('/register/login')
        }
       
        helper.sweetalert.toast(response.msg, "success");
        setAddsLoadig(false);
      })
      .catch((error) => {
        setAddsLoadig(false);
        console.error(error);
      });

    // Proceed with login if no errors
  };

  console.log(vaildData,"vaildData")

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} lg={10} xl={10}>
          <CardGroup>
            <Card>
              <header style={{ backgroundImage: `url(${shutterstock})` }}>
                <div className="color-overlay ">
                  <div
                    onClick={() => navigate("/")}
                    className="back-home mt-3 backBtn d-flex align-items-center justify-content-between gap-2"
                  >
                    {" "}
                    {/* <div className="mb-1">
                      <img
                        style={{ height: "14.17px", width: "13.46px" }}
                        src={backHome}
                      />
                    </div> */}
                    <div
                      className="mt-0 backtext fontWeight400 "
                      style={{
                        width: "60px",
                        fontSize: "30px",
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <i class="fa-solid fa-arrow-left"></i>
                    </div>
                  </div>
                </div>
              </header>
            </Card>
            <Card className="form-style loginSignup">
              <div className="container-fluid " style={{ padding: "10px" }}>
                <Container>
                  {" "}
                  <div>
                    <h3>
                      Welcome to{" "}
                      <b className="seprate-heading">Skill Linkers?</b>
                    </h3>
                  </div>
                </Container>

                <Container>
                  <div className="tab-content mt-7">
                    <Row>
                      <Col xs={12} lg={9} md={9} xl={11}>
                        {otpSuccess == true  && otpValid == false ? (
                          <Form onSubmit={handleValidateOtp}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>School Domain Email</Form.Label>
                              <Form.Control
                                name="email"
                                className="linearBorder"
                                size="lg"
                                type="email"
                                placeholder="Enter Email"
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors.emailLogin}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Enter otp</Form.Label>
                              <Form.Control
                                name="otp"
                                className="linearBorder"
                                size="lg"
                                type="number"
                                placeholder="Enter otp"
                                required
                                defaultValue={vaildData.OTP}
                              />
                            </Form.Group>

                            <div className="d-grid gap-2">
                              <Button
                                type="submit"
                                className="linearBg"
                                variant="secondary"
                                size="lg"
                              >
                                {addsLoadig ? (
                                  <Spinner animation="border" />
                                ) : (
                                  <span>Submit Otp </span>
                                )}
                              </Button>
                            </div>
                          </Form>
                        ) :  otpValid == false && (
                          <Form onSubmit={handleSendotp}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>School Domain Email</Form.Label>
                              <Form.Control
                                name="email"
                                className="linearBorder"
                                size="lg"
                                type="email"
                                placeholder="Enter Email"
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors.emailLogin}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <div className="d-grid gap-2">
                              <Button
                                type="submit"
                                className="linearBg"
                                variant="secondary"
                                size="lg"
                              >
                                {addsLoadig ? (
                                  <Spinner animation="border" />
                                ) :  (
                                  <span>Send Otp</span>
                                )}
                              </Button>
                            </div>
                          </Form>
                        )}

                        {
                          otpValid == true && otpSuccess == false && 
                          <Form onSubmit={handlePasswordChange}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>School Domain Email</Form.Label>
                            <Form.Control
                              name="email"
                              className="linearBorder"
                              size="lg"
                              type="email"
                              placeholder="Enter Email"
                              required
                              disabled
                              defaultValue={vaildData.userId}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formErrors.emailLogin}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Enter New Pasword</Form.Label>
                            <Form.Control
                              name="password"
                              className="linearBorder"
                              size="lg"
                              type="number"
                              placeholder="Enter New Password"
                              required
                            />
                          </Form.Group>

                          <div className="d-grid gap-2">
                            <Button
                              type="submit"
                              className="linearBg"
                              variant="secondary"
                              size="lg"
                            >
                              {addsLoadig ? (
                                <Spinner animation="border" />
                              ) : (
                                <span>Reste Password </span>
                              )}
                            </Button>
                          </div>
                        </Form>
                        }
                      </Col>
                    </Row>
                  </div>
                </Container>
              </div>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default EmailPage;
