import React from "react";
import "./style.css";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import logo from "../../assets/logo.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="page-footer font-small blue pt-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 mt-md-0 mt-3">
            <Row>
              <Col md={6}>
                <div>

      
                <div    onClick={() => navigate("/")} style={{cursor:"pointer"}} >
                  <Image src={logo} rounded />
                </div>

                <div style={{ textAlign: "justify"}}>
                  <Card style={{ backgroundColor: "#F8FAFC", border: "none" }}>
                    <Card.Body>
                      <Card.Text>
                        <p className="footer-description fontWeight400">
                          We bring together skilled highschoolers with a
                          passion to build and share their personal skills to
                          develop new talents and create lasting connections.
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                </div>
              </Col>
              <Col   md={3}>
                <div className="childmain">
                  <h5 className="text-uppercase footer-headings">Company</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="footer-links mt-3"
                        onClick={() => navigate("/about")}
                        style={{ cursor: "pointer" }}
                      >
                        About Us
                      </a>
                    </li>
                    <li className="mt-3">
                      <a className="footer-links mt-5" href="#!">
                        Other Informations
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={3}>
                <div className="childmain">
                  <h5 className="text-uppercase footer-headings">Help</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a className="footer-links mt-3" href="#!"
                      onClick={() => navigate("/termsandconditions")}
                      >
                        Terms of Service
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        className="footer-links mt-5"
                        onClick={() => navigate("/faq")}
                        style={{ cursor: "pointer" }}
                      >
                        FAQ's
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>

          <hr className="clearfix w-100 d-md-none pb-0" />
        </div>
      </div>
      <Container>
        <hr />
      </Container>
      <div className="footer-copyright text-center py-3 copyrighttext">
        © Copyright 2024, All Rights Reserved by
        <a className="gradiant-text link-text"> Skill Linker</a>
      </div>
    </footer>
  );
};

export default Footer;
