import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Image } from "react-bootstrap";
import homepage from "../../assets/homepage.svg";
import "./style.css";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import headphones from "../../assets/headphones.svg";
import chartBar from "../../assets/chart-bar.svg";
import archiveContent from "../../assets/archive-content.svg";
import group from "../../assets/Group.svg";
import folderFavorite from "../../assets/folder-favorite.svg";
import chart from "../../assets/chart.svg";
import person from "../../assets/person.svg";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import homepagenew from "../../assets/homepagenew.jpeg";

const HomePage = () => {
  const navigate = useNavigate();
  const skillLinkers = [
    {
      title: "Skill Exchange Platform",
      image: headphones,
      description:
        "Our platform serves as a dynamic hub for highschoolers to exchange skills seamlessly. Whether you're honing your coding abilities or perfecting your public speaking, our system pairs you with peers eager to share their expertise.",
    },
    {
      title: "Randomized Pairing Algorithm",
      image: chartBar,
      description:
        "Through our innovative randomized pairing algorithm, each interaction brings fresh learning opportunities. This approach fosters diverse learning experiences, empowering you to develop a well-rounded skill set.",
    },
    {
      title: "Community-Centric Focus",
      image: group,
      description:
        "Beyond skill development, we prioritize community building. Our platform cultivates a supportive environment where meaningful connections flourish, enriching your learning journey.",
    },
    {
      title: "Intuitive Interface",
      image: archiveContent,
      description:
        "Designed for ease of use, our user-friendly interface simplifies profile creation, skill exchange, and connection with your learning partner. Our streamlined processes ensure a seamless experience.",
    },
    {
      title: "Mission Statement",
      image: folderFavorite,
      description:
        "Our mission is to empower highschoolers by facilitating skill enhancement and fostering a network of collaborative learning. Join us in creating a community where every skill shared propels personal and collective growth.",
    },
    {
      title: "Result",
      image: chart,
      description:
        "Embrace the opportunity to connect, learn, and grow with us. Together, let's pave the way for a brighter future through skill exchange and community collaboration.",
    },
  ];

  const [userData, setUserData] = useState("");

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userId");
    if (userDataString) {
      setUserData(userDataString);
    }
  }, [sessionStorage.getItem("userId")]); // Empty dependency array to run this effect only once when the component mounts

  return (
    <>
      <header
        style={{
          backgroundImage: `linear-gradient(to right, #16C062 , #00BCE0)`,
        }}
      >
        <div className="color-overlay d-flex justify-content-center align-items-center">
          <div className="text-center content">
            <h1 className="bannerHeading mt-0">
              Welcome to <b className="seprate-heading">Skill Linkers!</b>
            </h1>
            <Container>
              <Container>
                <p className="bannerPragraph mt-5">
                  We bring together skilled highschoolers with a passion to
                  build and share their personal skills to develop new talents
                  and create lasting connections.
                </p>
              </Container>
            </Container>
            <p className="bannerPragraph mt-5"></p>
            {!userData && (
              <p className="bannerPragraph mt-5">
                <i>
                  <b>
                    <u>
                      <Link className="link-style" to={"/register/signup"}>
                        Create an Account
                      </Link>
                    </u>
                  </b>{" "}
                  and join the community today!
                </i>
              </p>
            )}
            {/* {userData && ( */}
              <Button
                onClick={() => {
                  navigate("/skills");
                }}
                className="mt-4 skill-listBtn fontWeight400 Btn-bg mb-2"
              >
                View list of skills
              </Button>
            {/* )} */}
          </div>
        </div>
      </header>
      <div className="homepage-container"></div>
      <Container>
        <div className="text-center mt-5">
          <div className="aboutSkills">
            Why <b className="seprate-heading">Skill Linkers?</b>
          </div>
          {/* <p className="mt-3">
            Lorem ipsum dolor sit amet, consectetur adipis elit
          </p> */}
        </div>
        <div className="mt-5">
          <CardGroup>
            {skillLinkers.map((ele, i) => (
              <div key={i} className="col-lg-4 col-md-6 border-configuration">
                <Card className="text-center" style={{ border: "none" }}>
                  <div
                  // className="border-configuration"
                  >
                    <Card.Img
                      style={{
                        height: "37.64px",
                        width: "37.64px",
                        marginTop: "35px",
                      }}
                      variant="top"
                      src={ele.image}
                    />
                  </div>
                  <Card.Body>
                    <div className="cardHeading">{ele.title}</div>
                    <div className="cardDescription fontWeight400">
                      {ele.description}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </CardGroup>
        </div>
      </Container>
      <Container>
        <div className="mt-5">
          <Row xs={1} md={2} className="g-4 border-none">
            <Col>
              <Card className="mt-5 border-none">
                <Card.Body>
                  <Card.Text className="mt-5">
                    <p className="mt-4 home-text2">
                      “Connecting and building skills with others has never been
                      easier”
                    </p>
                    <p className="mt-4 home-text2">
                      “With Skill Linkers, a tap of a button can lead to a new
                      skill, and a new relationship with another skilled
                      highschoolers like you!”
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="border-none">
                <Card.Body>
                  <Card.Text>
                    <img
                      className="img-thumbnail border-none"
                      src={homepagenew}
                      alt="..."
                    />
                    {/* <img src="..." alt="..." class="img-thumbnail"></img> */}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      {/* <Footer/> */}
    </>
  );
};

export default HomePage;
