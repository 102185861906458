import React, { useState, useEffect } from "react";
import { Col, Container, ListGroup, Row, Collapse, Spinner } from "react-bootstrap";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import SkillsModel from "models/skills.model";
import { useLocation } from "react-router-dom";
import styles from "./style.module.css";

const ListOfSkills = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [openIndex, setOpenIndex] = useState(-1);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const parameters = {};
  for (let [key, value] of searchParams) {
    parameters[key] = value;
  }

  const getListData = async () => {

    setIsLoading(true);
    await SkillsModel.allListInhomePage()
      .then((result) => {
        setIsLoading(false);
        if (result) {
          setIsLoading(false);
          setDataList(result?.data?.data);
          setCurrentPage(result?.meta?.current_page);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getListData();
  }, [location]);

  console.log(dataList,"dataList")

  const toggleCollapse = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const [subSkills, setSubSkills] = useState("");
  const getListDataSubSkills = async (id) => {
    const data = {
      categoryId: parseInt(id),
    };
    await SkillsModel.skillNameBycategoryId(data)
      .then((result) => {
        setIsLoading(false);
        if (result) {
          setSubSkills(result?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <div className="mb-5">
      <Container>
        <div className="text-center">
          <div className="gradiant-text text-center gradiantHeadings">
            List of Skills
          </div>
          <p className="subText">
            Everything you need to know about the skills.
          </p>
        </div>
        <Container>
          <Row className="justify-content-md-center mt-5">
            <Col xs lg={7}>
              <div className="text-justify">
                <ListGroup className="mb-2" variant="flush">

                  { isLoading ?  <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#01A8CC",
                          }}
                        >
                          <Spinner></Spinner>
                        </div>:
                  
                  dataList &&
                    dataList?.map((item, index) => (
                      <ListGroup.Item
                        key={index}
                        // className="d-flex justify-content-between"
                      >
                        <div
                          className="ms-2 me-auto mb-3"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            className={`fw-bold ${styles.listHeadings} fontWeight500`}
                          >
                            {item.category_name}
                          </div>
                          <div
                            onClick={() => {
                              toggleCollapse(index);
                              // getListDataSubSkills(item.id);
                            }}
                            className={`d-inline-block ${
                              styles.toggleIcon
                            } cursor-pointer ${
                              openIndex === index ? styles.open : ""
                            }`}
                          >
                            {openIndex === index ? (
                              <FiMinusCircle color="#7F56D9" size={20} />
                            ) : (
                              <FiPlusCircle color="#7F56D9" size={20} />
                            )}
                          </div>
                        </div>
                        <Collapse in={openIndex === index}>
                          <ul>
                            {item?.skill_name &&
                              item?.skill_name?.map((item, index) => {
                                return (
                                  <>
                                 
                                    <li
                                      className={`${styles.listHeadings}`}
                                    >
                                      {  item.skill_name}
                                    </li>
                                  
                                  </>
                                );
                              })}
                          </ul>
                        </Collapse>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default ListOfSkills;
